import React from "react";
import MainContainer from "../../components/container";
import PersistentDrawer, { DrawnerElement } from "../../components/persistenDrawer";
import Users from "../../views/Users";

const Usuarios: React.FC = () => {
    return (
        <MainContainer name="Usuarios">
            <UsuariosPanel />
        </MainContainer>
    )
};

const UsuariosPanel: React.FC = () => {
    const elements: DrawnerElement[] = [
        { title: "Control de Usuarios", label: "Control de Usuarios", view: <Users /> },
    ];
    const [selected, setselected] = React.useState<DrawnerElement>(elements[0]);
    function onSavedPost(){
        setselected(elements[1]);
    };
    return(
        <PersistentDrawer selected={selected} setselected={setselected} elements={elements}></PersistentDrawer>
    )
}

export default Usuarios;