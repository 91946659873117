import { Save } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { MUITable, MUITableColumn, MUITableRow } from "../../components/MUIComponents";
import { getPolls, newPoll, updatePoll } from "../../services/juntanominadora";

const DashMag: React.FC = () => {
    const SelElement: React.FC<{ value: number, id: string, fnPost: (val: boolean) => void }> = ({ value, id, fnPost }) => {
        function onChangeValue(e: any) {
            const txt: number = e.target.value;
            updatePoll({ state: txt, id: id }).then(v => {
                if (v.data.successed) {
                    alert("La modificacion ha sido guardada.");
                    fnPost(false);
                };
            });
        }
        return (
            <select className="form-control" onChange={onChangeValue} defaultValue={value}>
                <option value={0} >Inactivo</option>
                <option value={1} >Activo</option>
                <option value={2} >Finalizado</option>
            </select>
        )
    }

    const [upd, setupd] = useState(false);
    const [rows, setRows] = useState<MUITableRow[]>([]);
    const [name, setname] = useState("");

    const columns: MUITableColumn[] = [
        { label: "Nombre" },
        { label: "Fecha" },
        { label: "Estado" }
    ];

    function savePoll() {
        newPoll({ name: name }).then(v => {
            if (v.data.successed) {
                setupd(false);
                setname("");
            };
        });
    };

    useEffect(() => {
        if (!upd) {
            setupd(true);
            getPolls().then(v => {
                if (v.data.successed) {
                    const polls: any = v.data.polls;
                    const rowstemp: MUITableRow[] = []
                    for (let poll of polls) {
                        const polltemp = {
                            link: poll.id,
                            border: (poll.estado === 1),
                            columns: [
                                { label: poll.nombre },
                                { label: moment(poll.created_at).format("LLL") },
                                { label: <SelElement id={poll.id} value={poll.estado} fnPost={setupd} /> },
                            ]
                        };
                        rowstemp.push(polltemp);
                    };
                    setRows(rowstemp);
                };
            });
        };
    }, [upd, name]);

    return (
        <div className="row py-2">
            <div className="col-md-12">
                <div className="bg-light shadow-sm rounded py-4 px-4 text-left mb-4">
                    <div className="row">
                        <div className="col-md-9">
                            <h5>AGREGAR VOTACIÓN</h5>
                            <TextField label="Nombre" value={name} onChange={(e) => setname(e.target.value)} fullWidth variant="standard" className="mt-1 mb-2" />
                        </div>
                        <div className="col-md-3 d-flex justify-content-center align-items-center">
                            <Button onClick={savePoll} startIcon={<Save />}>Guardar</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <MUITable columnLabels={columns} rows={rows} simple />
            </div>
        </div>
    )
};

export default DashMag;