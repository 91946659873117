import { Search } from "@mui/icons-material";
import { Button, IconButton, Pagination } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { MediaCard } from "../components/cards";
import { getNews } from "../services/api";
import { CategoriesComponent } from "./edit";
const YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const FACEBOOK_PREFIX = "https://www.facebook.com/plugins/video.php?href=";

const Publicaciones: React.FC = () => {

    const [upd, setUpd] = useState(false);
    const [limit] = useState(8);
    const [page, setpage] = useState(0);
    const [count, setCount] = useState(0);
    const [posts, setPosts] = useState<any[]>([]);
    const [search, setsearch] = useState("");
    const [selectedCat, setselectedCat] = useState("");

    function getYoutubeSrc(url: string) {
        var id = url && url.match(YOUTUBEMATCH_URL)![1];
        return {
            srcID: id,
            srcType: 'youtube',
            url: url
        };
    };

    function getMedia(val: any[] | undefined): {image: string, type: 1|2}{
        try {
            if (val) {
                const arrayval: any[] = [];
                for (let v in val) {
                    arrayval.push(val[v]);
                };

                const images = arrayval.filter((obj: any) => {
                    return obj.type === "IMAGE";
                });

                const videos = arrayval.filter((obj: any) => {
                    return obj.type === "draft-js-video-plugin-video"
                });

                const idx = 0;

                if (images.length === 0) {
                    if (videos[idx]) {
                        const t = YOUTUBEMATCH_URL.test(videos[idx].data.src) ? getYoutubeSrc(videos[idx].data.src) : { srcID: "" };
                        const r = YOUTUBEMATCH_URL.test(videos[idx].data.src) ? YOUTUBE_PREFIX + t.srcID : FACEBOOK_PREFIX + videos[idx].data.src;
                        return { image: r, type: 2 }
                    };
                } else {
                    const r = images[idx].data.src.replace("http://10.170.170.30:3010", "").replace("http://172.16.0.24:3010", "");
                    return { image: r, type: 1 }
                };

            };
        } catch (error) {
        };
        return {image:"", type:1};

    };

    function handleCategory(e:any){
        const category = e.target.value;
        setselectedCat(category);
        setUpd(false)
    };

    useEffect(() => {
        if (!upd) {
            setUpd(true);
            getNews(limit.toString(), (limit * page).toString(), search, selectedCat).then(v => {
                if (v.data.successed) {
                    setPosts(v.data.posts);
                    setCount(Math.ceil(parseInt(v.data.count) / limit));
                };
            });
        };
    }, [upd, posts, limit, page, search,selectedCat]);

    return (
        <div className="container-fluid">
            <div className="row py-2 mb-3">
                <div className="d-flex col-lg-5 col-md-7 col-sm-12 align-items-center justify-content-center">
                    <input className="form-control" style={{ marginRight: 10 }} placeholder="Busqueda" value={search} onChange={(e) => { setsearch(e.target.value); setUpd(false) }} />
                    <Button variant="contained"><Search /></Button>
                </div>
                <div className="col-lg-7 col-md-5 col-sm-12">
                   <CategoriesComponent isNull selected={selectedCat} onChange={handleCategory} />
                </div>
            </div>
            <div className="row">
                {posts.map((val) => {
                    const t = getMedia(val.images)
                    return (
                        <div key={val._id} className="col-lg-3 col-md-4">
                            <MediaCard title={val.title} date={moment(val.title).format("LL")} description={val.description} id={val._id} type={t.type} image={t.image} />
                        </div>
                    )
                })}
            </div>
            <div className="d-flex justify-content-center"><Pagination count={count} onChange={(e, page) => { setpage(page - 1); setUpd(false) }} /></div>
        </div>
    )
};

export default Publicaciones;