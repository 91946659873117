export function comprimirImagen(imagenComoArchivo: any, porcentajeCalidad: number):Promise<any> {
    return new Promise<any>((resolve, reject) => {
        const $canvas = document.createElement("canvas");
        const imagen = new Image();
        imagen.onload = () => {
            $canvas.width = imagen.width;
            $canvas.height = imagen.height;
            $canvas.getContext("2d")!.drawImage(imagen, 0, 0);
            $canvas.toBlob(
                (blob:any) => {
                    if (blob === null) {
                        return reject(blob);
                    } else {
                        resolve(blob);
                    }
                },
                "image/jpeg",
                porcentajeCalidad / 100
            );
        };
        imagen.src = URL.createObjectURL(imagenComoArchivo);
    });
};