import axios from "axios";

axios.interceptors.request.use(
    config =>{
        const key = localStorage.getItem("tk");
        config.headers!.authorization = `Bearer ${key? key: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.Im9xaWVqb3F3ZWxwZXJyb2FtYXJpbGxvRFNGQUdTREdzZGdkcmhmYmRlMzQyNWxhZHJheWxhZHJhZHFkcXdkIHF3ZG5vbnF3aWV3bnFlcWVxZXRqaGs3NDMi.TCuZwTTp_D6fdJ0fi_C_n79b3FUniZIRQkEBtcfNhd3uAEup6jzvfyoj3LySoeDMpT2_ZuMJBSK4jwEKETBLsA"}`;
        return config;
    }, 
    error =>{
        return Promise.reject(error);
    }
);

//export const query: string = "http://172.16.0.24:3010";
//export const query: string = "http://localhost:3010";
export const query: string = "https://api.congresonacional.hn";
export const query30: string = "https://api30.congresonacional.hn:5001";


//Meeting
export function newMeeting(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/api/meeting`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getApiMeetings(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/meeting`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getApiMeeting(id:string): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/meeting/${id}`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function updateApiMeeting(id:string, data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.put(`${query}/api/meeting/${id}`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

//USUARIOS

export function login(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/tokens`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function loginWithCard(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/loginwithcard`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getLogBySeatNumber(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query30}/logbyseatnumber`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};



export function VerifyToken(token: string): Promise<any>{
    return new Promise<any>( resolve => {
        axios.put(`${query}/tokens`, {key:token})
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function addUser(user: any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/api/users`, user)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getUsers(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/users`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getUserRols(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/user_rol`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function addRol(data: any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/api/rols`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

//COMISIONES

export function getComissions(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/comissions` )
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getOneComission(id:string): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/comissions/${id}` )
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getComissionsByMember(id:string): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/v2/comissions/${id}` )
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getComissionMembers(id: string): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/comissions/members/${id}` )
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};


//TRANSPARENCIA

export function sendFormTransparencia(route: string,data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}${route}`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

//PUBLICACIONES

export function addCategory(data: any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/api/post_categories`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function deletePost(id:string): Promise<any>{
    return new Promise<any>( resolve => {
        axios.delete(`${query}/api/posts/${id}`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function addPost(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/api/posts`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function updatePost(id:string, data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.put(`${query}/api/posts/${id}`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getPostById(id:string): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/posts/${id}` )
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getImportantPosts(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/important/posts` )
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getCategories(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/categories` )
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getNews(limit: string, skip: string, search: string, category: string): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/news?limit=${limit}&skip=${skip}&search=${search}&category=${category}` )
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};


export function postFile(data:FormData, father: string = "root", descripcion: string = "Sin Descripcion"): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/file`, data, { headers:{body: JSON.stringify({father: father, descripcion: descripcion}) }})
        .then(result => resolve(result) )
        .catch(error => resolve( {data: {successed:false}} ) );
    });
};

export function putOpciones(name:string, value:string): Promise<any>{
    const data = {name: name, value: value};
    return new Promise<any>( resolve => {
        axios.put(`${query}/api/shure/opciones`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getUltimaVotacionPublic(VotingSession_id:number): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/shure/ultimavotacion/${VotingSession_id}`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};