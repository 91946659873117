import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DraftEditor from '../components/DraftEditor';
import useFormHelper from '../helpers/useFormHelper';
import { addPost, getCategories, getPostById, updatePost } from '../services/api';

interface EditorProps {
    onSavedPost: () => void;
};

const Editor: React.FC<EditorProps> = ({ onSavedPost }) => {
    const [upd, setUpd] = useState(false);
    const [updating, setUpdating] = useState(true);
    const [content, setContent] = useState<any>(undefined);
    const [initialContent, setInitialContent] = useState<any>(undefined);

    const states = useState({
        title: "",
        description: "",
        category: "",
        featured: false,
        visible: false
    });
    const { id }: any = useParams();
    const {
        values,
        handleChange,
        updateValues,
    } = useFormHelper(states);

    function SavePost(e: any) {
        const data = {
            title: values.title,
            description: values.description,
            category: values.category,
            featured: values.featured,
            visible: values.visible,
            body: e
        };
        if (!id && e) {
            addPost({ ...data, createdAt: new Date(), }).then(v => {
                if (v.data.successed) {
                    onSavedPost()
                };
            });
        } else {
            const dataUpdate = {
                ...data,
                id: id,
            };
            updatePost(id, dataUpdate).then(v => {
                if (v.data.successed) {
                    alert("Se han guardado los cambios!")
                    onSavedPost()
                } else {
                    alert("Ha ocurrido un error!")
                };
            });
        };
    };

    useEffect(() => {
        if (!upd) {
            setUpd(true);
            updateValues({
                ...values,
                visible: true
            })

            if (id) {
                setUpdating(true);
                getPostById(id).then(v => {
                    if (v.data.successed) {
                        const ptemp = v.data.post;
                        updateValues({
                            ...values,
                            title: ptemp.title,
                            description: ptemp.description,
                            category: ptemp.category,
                            featured: ptemp.featured,
                            visible: ptemp.visible
                        });
                        setInitialContent(v.data.post.body)
                    };
                    setUpdating(false)
                });
            };
        };
    }, [upd, updating, id, values, updateValues, initialContent]);

    return (
        <div>
            <div className='container py-2'>

                {(id) ? <div className='mb-4'>
                    <label><b>Título</b></label>
                    <input className="form-control my-2" name="title" value={values.title} onChange={handleChange} />
                    <label><b>Descripción</b></label>
                    <input className="form-control my-2" name="description" value={values.description} onChange={handleChange} />
                </div> : <div>
                    <TextField className="my-2" fullWidth name="title" value={values.title} onChange={handleChange} label="Título" variant={"standard"} />
                    <TextField className="my-2 mb-4" fullWidth multiline name="description" value={values.description} onChange={handleChange} label="Descripción" variant={"standard"} />
                </div>
                }
                <CategoriesComponent selected={values.category} onChange={handleChange} />

                {(id && !updating) &&
                    <div>
                        <FormControlLabel control={<Checkbox name="featured" defaultChecked={values.featured} checked={values.featured} onChange={(e) => { handleChange({ target: { name: "featured", value: e.target.checked } }); }} />} label="Marcar como destacado" />
                        <FormControlLabel control={<Checkbox name="visible" defaultChecked={values.visible} checked={values.visible} onChange={(e) => { handleChange({ target: { name: "visible", value: e.target.checked } }); }} />} label="Visible" />
                    </div>
                }

                {!id && (
                    <div>
                        <FormControlLabel control={<Checkbox name="featured" checked={values.featured} onChange={(e) => { handleChange({ target: { name: "featured", value: e.target.checked } }); }} />} label="Marcar como destacado" />
                        <FormControlLabel control={<Checkbox name="visible" defaultChecked checked={values.visible} onChange={(e) => { handleChange({ target: { name: "visible", value: e.target.checked } }); }} />} label="Visible" />
                    </div>
                )}

                <div>
                    {((id && initialContent) || !id) &&
                        <DraftEditor initialContent={initialContent} exportDataRaw={setContent} onSavePost={SavePost} />
                    }
                </div>
            </div>
        </div>
    )
};

interface CCProps {
    selected: string,
    isNull?:boolean,
    onChange: (e: any) => void;
}

export const CategoriesComponent: React.FC<CCProps> = ({ selected, isNull, onChange }) => {
    const [upd, setupd] = useState(false);
    const [cat, setcat] = useState([]);

    useEffect(() => {
        if (!upd) {
            setupd(true);
            getCategories().then(v => {
                if (v.data.successed) {
                    setcat(v.data.categories);
                };
            });
        };
    }, [upd, cat]);
    return (
        <FormControl fullWidth className="py-2">
            <InputLabel id="category">Categoria</InputLabel>
            <Select
                labelId='category'
                label="Categoria"
                value={selected ? selected : 1}
                name="category"
                onChange={onChange}
            >
                <MenuItem value={1} disabled={!isNull}>{"SELECCIONAR"}</MenuItem>
                {cat.map((val: any) =>
                    <MenuItem key={val._id} value={val._id}>{val.title}</MenuItem>
                )}
            </Select>
        </FormControl>
    )
}


export default Editor;

