import { Avatar, Button, CircularProgress } from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import { useState, useEffect } from "react";
import { BarChart } from "../../components/charts";
import ReportPdf, { ReportPdf2 } from "../../components/Reportes";
import { getVotos2 } from "../../services/juntanominadora";
import domtoimage from "dom-to-image";
import moment from "moment";
import BasicTabs from "../../components/MUIComponents";
import { ResultsView } from "./votantes";

const RTab: React.FC<{ list: any[] }> = ({ list }) => {
    return (
        <div>
            {list.map((vote, idx) =>
                <div className="d-flex mb-2 shadow-sm rounded" style={{ backgroundColor: "#163D79" }}>
                    <div className="px-3 py-3 d-flex align-items-center justify-content-center " style={{ color: "white" }}>
                        <b>{idx < 9 && "0"}{idx + 1}</b>
                    </div>

                    <div className="bg-white py-3 px-3 d-flex align-items-center" style={{ width: "100%", height: "100%" }}>
                        <Avatar alt={vote.nombre} src={`images/candidatos/${vote.candidato}.jpg`} />
                        <small className="mx-3">{vote.nombre}, <b>Votos obtenidos: </b>{vote.votos}</small>
                    </div>
                </div>
            )}
        </div>
    )
};

const Resumen: React.FC<{limit: number, candidatos:any[], grows:any[], btnReport: () => void, btnReport2: () => void}> = ({limit, candidatos, grows, btnReport, btnReport2}) => {
    if(candidatos.length === 0){ 
        return(
        <div className="container-fluid text-center">
            <h6><b>No hay datos disponibles</b></h6>
        </div>
    )}
    return (
        <div>
            <div className="pb-3"><b>Candidatos Seleccionados (</b> <small> Minimo: {limit + 1} votos </small><b>)</b></div>
            <div id="g-1" style={{ border: 0 }} >
                {candidatos.length > 0 && (
                    <BarChart list={grows} />
                )}
            </div>
            <RTab list={candidatos.sort((a, b) => b.votos - a.votos)} />
            {candidatos.length > 0 && (
                <div className="py-3 text-center">
                    <Button variant="contained" className='d-none' onClick={btnReport2}>Descargar 2</Button>
                    <Button variant="contained" onClick={btnReport}>Descargar</Button>
                    <Button variant="contained" className=" mx-2" onClick={()=>{}}>Proyectar</Button>
                </div>)}
        </div>
    )
}

const Resultados: React.FC = () => {
    const [upd, setupd] = useState(true);
    const [votes, setvotes] = useState<any[]>([]);
    const [tabs, settabs] = useState<any[]>([]);


    async function btnReport(vtemp: any[], vt: any[]) {
        if (vtemp.length > 0 && vt.length > 0) {
            const l = document.getElementById("g-1")!;
            const ltemp = await domtoimage.toPng(l)

            const t = await pdf(<ReportPdf list={vtemp} mainGraph={ltemp} date={moment(vt[0].inicio).format("LL")} rounds={vt.length} total={vtemp.length} />).toBlob();

            const a = document.createElement('a')
            a.href = URL.createObjectURL(t)
            a.download = `Reporte`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } else {
            alert(vtemp.length)
        }
    };

    async function btnReport2(r1: any[], r2: any[], r3: any[]) {
        if (r1.length > 0) {

            const t = await pdf(<ReportPdf2 r1={r1} r2={r2} r3={r3} date={moment(new Date()).format("LL")} />).toBlob();

            const a = document.createElement('a')
            a.href = URL.createObjectURL(t)
            a.download = `Reporte`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } else {
            alert(r1.length)
        }
    };


    useEffect(() => {
        const interval = setInterval(() => {
            getVotos2().then(v => {
                setupd(false);
                if (v.data.successed) {
                    const votes = v.data.votos;

                    const r1 = v.data.r1;
                    const r2 = v.data.r2;
                    const r3 = v.data.r3;

                    const limit: number = parseInt(v.data.limite);
                    const candidatos: any[] = votes.filter((v: any) => { return (v.votos > limit && v.candidato_id !== "1") });

                    //alert(limit)
                    const grows: any[] = [["Candidato", "Votos"]];
                    grows.push(["", 0]);

                    const fl: any[] = candidatos;

                    for (let v of fl) {
                        grows.push([v.nombre, v.votos]);
                    };

                    const tabs: any[] = [];

                    tabs.push({ children: <ResultsView inColumns loop />, label: "En curso" })

                    tabs.push({
                        children: 
                        <Resumen 
                            limit={limit} 
                            candidatos={candidatos} 
                            grows={grows} 
                            btnReport={()=>btnReport(candidatos, v.data.votaciones)}
                            btnReport2={()=>btnReport2(r1,r2,r3)}
                        />, label: "Resumen"
                    });
                    for (let vt of v.data.votaciones) {
                        const lt: any[] = votes.filter((voto: any) => { return voto.votacion_id === vt.votacion_id });
                        const grows2: any[] = [["Candidato", "Votos"]];
                        grows2.push(["", 0]);
                        const fl2: any[] = lt.sort((a, b) => b.votos - a.votos);
                        for (let v of fl2) {
                            grows2.push([v.nombre, v.votos]);
                        };
                        tabs.push(
                            {
                                children:
                                    <div>
                                        <div id="g-1" style={{ border: 0 }} >
                                            <BarChart list={grows2} />
                                        </div>
                                        <RTab list={fl2} />
                                    </div>
                                , label: vt.votacion
                            }
                        );
                    };

                    settabs(tabs)
                    setvotes(votes);
                };

            });
        }, 1000);
        return () => clearInterval(interval);
    }, [upd, tabs, votes]);

    if (upd) {
        return (
            <div className="d-flex justify-content-center align-items-center py-4">
                <CircularProgress />
            </div>
        )
    }
    //            <PDFViewer style={{ width: "100%", height: "80vh" }} >
    //                <ReportPdf2 list={vc} date={moment(new Date()).format("LL")} />
    //            </PDFViewer>
    return (
        <div>
            <BasicTabs tabs={tabs} />
        </div>
    )
};

export default Resultados;