import { PDFViewer } from '@react-pdf/renderer';
import * as React from "react";
import { useEffect, useState } from "react";
import { getMag, getVotos, ObtenerVotosPorCongresista } from "../../services/juntanominadora";
import { PDFReporteVotosIndividualPapeleta, ReportCongressman } from '../../components/Reportes';

const ReportePrueba: React.FC = () => {
    const [puestos, setpuestos] = useState(0);
    const [electos, setelectos] = useState<any[]>([]);
    const [candidatos, setcandidatos] = useState<any[]>([]);
    const [votos, setvotos] = useState<any[]>([]);
    const [upd, setupd] = useState(false);

    const user =  {
        apellidos: "",
        cargo: "SUPLENTE",
        formula: "730",
        id: "148792",
        img: "/congresistas/JUAN ALBERTO BARAHONA MEJIA.jpg",
        nombres: "GUSTAVO ENRIQUE GONZÁLEZ MALDONADO",
        departamento: "CORTES",
    };

    const congresman = {
        apellidos: "RODRÍGUEZ ROSALES",
        cargo: "PROPIETARIO",
        formula: "730",
        id: "148792",
        img: "/congresistas/XIOMARA HORTENCIA ZELAYA CASTRO.jpg",
        nombres: "JOSÉ MANUEL",
        departamento: "CORTES2",

    }

    useEffect(() => {
        if (!upd) {
            setupd(true);

            getMag().then(v => {
                if (v.data.successed) {
                    const candidatos: any[] = v.data.candidatos;
                    setcandidatos(candidatos)
                };
            });
            getVotos().then(v => {
                if (v.data.successed) {
                    setpuestos(v.data.puestos);
                    setelectos(v.data.electos);
                };
            });

            
            ObtenerVotosPorCongresista("692").then(v => {
                if (v.data.successed) {
                    setvotos(v.data.activa);
                }
            });
        }


    }, [upd, puestos, candidatos, electos]);

    return (
        <PDFViewer style={{width: "100vw", height: "100vh"}}>
            <ReportCongressman list={[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]} departamentoCongresista={"CORTES"} congresista={"LUIS ROLANDO REDONDO GUIFARRO"} fotoCongresista={"/congresistas/LUIS ROLANDO REDONDO GUIFARRO.jpg"} formula={congresman} cargoCongresista={"SUPLENTE"}></ReportCongressman>
            
        </PDFViewer>
    )
};

export default ReportePrueba;
/*<PDFReporteVotosIndividualPapeleta user={user} image={"/congresistas/LUIS ROLANDO REDONDO GUIFARRO.jpg"} candidatos={candidatos}  votacionNumber={3} congresista={"MARCO ELIUD GIRÓN PORTILLO"} formula={congresman} votos={votos} puestos={puestos}/>*/