import Comunicaciones from "../pages/comunicaciones/comunicaciones";
import Magistrados from "../pages/juntanominadora/PanelUrna";
import PanelMagistrados from "../pages/juntanominadora/PanelAdministrador";
import VotacionDash from "../pages/juntanominadora/PanelCongresista";
import Reuniones from "../pages/salones/reuniones";
import Usuarios from "../pages/usuarios/usuarios";

const AdminRol: string = "62e98e926557138011012861";
const ComunicacionesRol: string = "6349cc1b596c7b0897225bba";
const SoporteRol: string = "632c90a9b8ac60a6040673b4"
const CongRol: string = "63b73a210e28330412aef4a6";
const admmcsj: string = "63c1ca8a3014c53e60f2b987";
const cong: string = "63cffa8ef4f3c08ab4e0bf38";

export const ModulePermission = [
    { rols: [AdminRol], module: Usuarios, name: "Principal" },
    { rols: [AdminRol], module: Usuarios, name: "Usuarios" },
    { rols: [AdminRol, ComunicacionesRol], module: Comunicaciones, name: "Comunicaciones" },
    { rols: [AdminRol, SoporteRol], module: Reuniones, name: "Reuniones" },
    { rols: [AdminRol, admmcsj, CongRol], module: Magistrados, name: "Magistrados" },
    { rols: [AdminRol, admmcsj], module: PanelMagistrados, name: "PanelMagistrados" },
    { rols: [AdminRol, cong], module: VotacionDash, name: "votacion" },
];

export const PathDefault = [
    { rol: AdminRol, path: "/" },
    { rol: ComunicacionesRol, path: "/comunicaciones" },
    { rol: SoporteRol, path: "/reuniones" },
    { rol: CongRol, path: "/votaciones" },
    { rol: admmcsj, path: "/magistrados" },
    { rol: cong, path: "/votacion" }
];


export function groupBy(list: any, keyGetter: any) {
    var e:{id: string, nombre: string, votos: number}[] = [];
    list.forEach((item: any) => {
        console.log(item)
        const key = keyGetter(item);
        const temp = e.find(t=> t.nombre === key);
        if(!temp){
            e.push({id: item.candidato_id, nombre: item.nombre, votos: 1})
        }else{
            temp.votos = temp.votos + 1;
            const ltemp = e.filter((f)=> f.nombre !== item.nombre);
            ltemp.push(temp);
            e = ltemp;
        }
    });

    return e;

    //const map = new Map();
    //list.forEach((item: any) => {
    //    const key = keyGetter(item);
    //    const collection = map.get(key);
    //    if (!collection) {
    //        map.set(key, [item]);
    //    } else {
    //        collection.push(item);
    //    }
    //});
    //return map;
}