import { Ballot, Poll, Videocam } from "@mui/icons-material";
import React from "react";
import MainContainer from "../../components/container";
import PersistentDrawer, { DrawnerElement } from "../../components/persistenDrawer";
import DashMag from "../../views/juntanominadora/dashMagistrados";
import Resultados from "../../views/juntanominadora/resultados";

const PanelMagistrados: React.FC = () => {
    return (
        <MainContainer name="PanelMagistrados">
            <DashMagistrados />
        </MainContainer>
    )
};

const DashMagistrados: React.FC = () => {
    const elements: DrawnerElement[] = [
        { title: "VOTACIONES", label: "Votaciones", view: <DashMag /> , icon:<Poll />},
        { title: `PROYECCION 0 VOTOS`, label: "Proyección 0 Votos", view: null, icon: <Videocam />, url: 'proyeccionvotos' },
        { title: `PROYECCION 1 TODOS`, label: "Proyección 1 Todos", view: null, icon: <Videocam />, url: 'proyeccion' },
        { title: `PROYECCION 2 ELECTOS`, label: "Proyección 2 Electos", view: null, icon: <Videocam />, url: 'proyeccionelegidos' },
        { title: `PROYECCION 3 ELECTOS BG`, label: "Proyección 3 Electos BG", view: null, icon: <Videocam />, url: 'proyeccionelegidosbg' },
        { title: `DIAGRAMA`, label: "Diagrama", view: null, icon: <Videocam />, url: 'diagrama' },
        { title: `DIAGRAMA QUÓRUM`, label: "Diagrama Quórum", view: null, icon: <Videocam />, url: 'diagramacompleto' },
        { title: `DIAGRAMA QUÓRUM IMPRESIÓN`, label: "Diagrama Quórum Impresión", view: null, icon: <Videocam />, url: 'diagramacompletoimpresion' },
        { title: `DIAGRAMA VOTOS SHURE`, label: "Diagrama Votos Shure", view: null, icon: <Videocam />, url: 'diagramacompletovotos' },
        { title: `DIAGRAMA VOTOS SHURE IMPRESIÓN`, label: "Diagrama Votos Shure Impresión", view: null, icon: <Videocam />, url: 'diagramacompletovotosimpresion' },
        { title: `DIAGRAMA VOTOS SISTEMA CSJ`, label: "Diagrama Votos Sistema CSJ", view: null, icon: <Videocam />, url: 'diagramacompletovotossistemacsj' },
        { title: `DIAGRAMA VOTOS SISTEMA CSJ IMPRESIÓN`, label: "Diagrama Votos Sistema CSJ Impresión", view: null, icon: <Videocam />, url: 'diagramacompletovotossistemacsjimpresion' },
        { title: "REPORTES", label: "Reportes", view: <Resultados /> , icon:<Ballot />}
    ];
    const [selected, setselected] = React.useState<DrawnerElement>(elements[0]);
    
    return(
        <PersistentDrawer hideHomeButton selected={selected} setselected={setselected} elements={elements} ></PersistentDrawer>
    )
};

export default PanelMagistrados;