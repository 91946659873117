import MainContainer from "../../components/container";
import {useState, useEffect} from "react";
import { getCongressman } from "../../services/juntanominadora";
import { useParams } from "react-router-dom";

const Perfiles: React.FC = () => {
    const [upd, setupd] = useState(false);
    const [magistrado, setmagistrado] = useState<any|undefined>(undefined);

    const {id}:any = useParams();
    
    useEffect(()=>{
        if(!upd && id){
            setupd(true);
            getCongressman(id).then(v=>{
                if(v.data.successed){
                    setmagistrado(v.data.congresista);
                };
            });
        };
    }, [upd, id]);
    
    return(
        <MainContainer name="perfiles">
            <h4>Perfil del magistrado</h4>
        </MainContainer>
    )
};

export default Perfiles;
