import { useState, useEffect } from "react";
import { VotacionElementCompleto } from "../../components/cards";
import { getVotosVotacionActiva as getSVVotosVotacionActiva, getSeatCoordinateComplete, getCongresistas, getLogDelegatesSeatNumberActiveConference, getGruposBancada, getCongresistasPublic, getLogDelegatesSeatNumberActiveConferencePublic, getGruposBancadaPublic, getVotosVotacionActivaPublic, getSeatCoordinateCompletePublic, getVotingResultPublic } from "../../services/juntanominadora";
import * as React from "react";
import stylesVotaciones from "../../styles/votacionStyles.module.css"

const DiagramaCompletoVotosImpresion: React.FC = () => {
    const [congresistas, setCongresistas] = useState<any[]>([]);
    const [gruposBancada, setGruposBancada] = useState<any[]>([]);
    const [SVDelegatesVotaron, setSVDelegatesVotaron] = useState<any[]>([]);
    //const [seatNumbers, setSeatNumbers] = useState<any[]>([]);
    const [coordenadas, setCoordenadas] = useState<any[]>([]);
    const [voltingResult, setVoltingResult] = useState<any[]>([]);
    

    useEffect(() => {
        // Obtener todos los congresistas
        if(congresistas.length === 0){
            getCongresistasPublic().then((v) => {
                if (v.data.successed) {
                    setCongresistas(v.data.content);
                };
            });
        }
        
        if(gruposBancada.length === 0){
            getGruposBancadaPublic().then((v) => {
                if (v.data.successed) {
                    setGruposBancada(v.data.content);
                };
            });
        }

        const inv = setInterval(() => {
            // Obtener Votos Shure
            getVotingResultPublic().then((v) => {
                if (v.data.successed) {
                    setVoltingResult(v.data.content);
                };
            });

            // Obtener Votos Votacion Activa Magistrados Sistema CSJ
            getVotosVotacionActivaPublic().then((v) => {
                if (v.data.successed) {
                    const votes = v.data.content;
                    //const seatNumbers = Array.from(new Set(votes.map((item: any) => item.seatnumber)))
                    //setSeatNumbers(seatNumbers);
                    const SVDelegatesVotaron = Array.from(new Set(votes.map((item: any) => item.user_id)))
                    setSVDelegatesVotaron(SVDelegatesVotaron);
                };
            });

            // Obtener Coordenadas Complete
            getSeatCoordinateCompletePublic().then((v) => {
                if (v.data.successed) {
                    setCoordenadas(v.data.content);
                };
            });
        }, 2000);
        return () => {clearInterval(inv)}
    }, [congresistas, gruposBancada, coordenadas, SVDelegatesVotaron, voltingResult]);

    return (
        <div className={`container-fluid ${stylesVotaciones.diagramaCompletoBackgroundTransparente}`}>
            {coordenadas.map((coor: any, index) => {
                const logDelegate = voltingResult.filter((vr:any) => { return vr.SeatNumber === coor.SeatNumber });
                const congresista = logDelegate.length ? congresistas.filter((c:any) => { return c.delegate_id === logDelegate[0].LogDelegate_id}) : [];
                // voto del shure
                const voto = congresista.length ? voltingResult.filter((vr:any) => { return vr.LogDelegate_id === congresista[0].delegate_id }) : [];
                // voto sistema votacion csj
                const delegateVoted = congresista.length ? SVDelegatesVotaron.filter((delegate:any) => { return delegate === `${congresista[0].delegate_id}` }) : [];

                const votedCSJ = delegateVoted.length ? true : false;

                const longerName = congresista.length ? Math.max(congresista[0].FirstName.split(" ")[0].length, congresista[0].LastName.split(" ")[0].length) : 0;
                let caracteresExtras = longerName - 8;
                if(longerName === 8){
                    caracteresExtras = 1;
                }
                const fonSizePercent = caracteresExtras > 0 ? 0.00625 - (caracteresExtras * 0.00052) : 0.00625;

                const congresistaName = congresista.length ? `${congresista[0].FirstName.split(" ")[0]} ${congresista[0].LastName.split(" ")[0]}`.toUpperCase() : ""; //coor.SeatNumber;

                const bancada = congresista.length ? gruposBancada.filter((b:any) => { return b.group_name === congresista[0].bancada.toUpperCase().trim() }) : [];

                const groupColor1 = bancada.length ?  bancada[0].group_color1 : '#9a9a9a';
                const groupColor2 = bancada.length ?  bancada[0].group_color2 : '#9a9a9a';


                return (
                    <div key={coor.SeatNumber}>
                        <VotacionElementCompleto backgroundTransparente={"#ffffff"} colorFontTransparente={"#000000"}  top={coor.YCoordinate} left={coor.XCoordinate} id={coor.SeatNumber} congresistaName={congresistaName} votedCSJ={votedCSJ} voto={voto} fonSizePercent={fonSizePercent} groupColor1={groupColor1} groupColor2={groupColor2}/>
                    </div>
                )
            })}
            <div style={{ position: "absolute", top: "4vh", right:"3vw", color: "#000000", fontSize: "1.2vw"}}><span>VOTOS RECIBIDOS: {voltingResult.length}</span></div> 
        </div>
    )
};
export default DiagramaCompletoVotosImpresion;
