import { Home, Poll, RestartAlt } from "@mui/icons-material";
import { Avatar, Button, CircularProgress } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import MainContainer from "../../components/container";
import PersistentDrawer, { DrawnerElement } from "../../components/persistenDrawer";
import { getCongressmenHasVoted, ObtenerVotosPorCongresista, VerificarActiva } from "../../services/juntanominadora";
import Votacion from "../../views/juntanominadora/votacion";
import { loginWithCard, getLogBySeatNumber } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import stylesVotaciones from "../../styles/votacionStyles.module.css"

const VotacionDash: React.FC = () => {
    return (
        <MainContainer name="votacion" >
            <VotacionView />
        </MainContainer>
    )
};

const WelcomeView: React.FC<{user: any|undefined}> = ({user}) => {
    if(user){
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center" style={{ flexDirection: "column", height:"100vh" }}>
                <img className="my-1" src="images/logo.png" alt="" height={200} />
                <Avatar className="mt-4" alt={user.dni} src={require(`../../imgs${user.img}`) ? require(`../../imgs${user.img}`) : ""} sx={{ width: "25vw", height: "25vw" }} />
                <h4 className="mt-5"><b>{`LE DAMOS LA BIENVENIDA H.D. ${user.nombres} ${user.apellidos}`}</b></h4>
            </div>
        )
    };
    return <div></div>
};

//Vista de los resultados por votacion y congresista
const VotedView: React.FC<{ id: string, user:any }> = ({ id, user }) => {
    const [upd, setupd] = useState(false);
    const [votos, setvotos] = useState<any[]>([]);

    useEffect(() => {
        if (!upd) {
            setupd(true);
            ObtenerVotosPorCongresista(id).then(async v => {
                if (v.data.successed) {
                    setvotos(v.data.activa);
                };
            });
        };
    }, [upd, votos, id]);

    return (
        <div className="container text-center py-3">
            <div className={`${stylesVotaciones.topBarCongresista}`} 
            style={{
                position: "absolute",
                width: "100%",
                top: "0px",
                left: "0px",
            }}>
                <Avatar className="" alt={`${user.nombres} ${user.apellidos}`} src={`images/${user.img}`} sx={{ width: "40px", height: "40px" }} />
                <p className="mx-3 m-0">H.D. {user.nombres} {user.apellidos}</p>
            </div>
            <img className="" style={{marginTop: "70px", marginBottom: "15px"}} src="images/logo.png" alt="" height={200} />
            <h5><b>CANDIDATOS SELECCIONADOS</b></h5>
            <div className="row my-3">
                {votos.map((val, idx) =>
                    <div key={idx} className="d-flex mb-2 shadow-sm rounded" style={{ backgroundColor: "#163D79" }}>
                        <div className="px-3 py-3 d-flex align-items-center justify-content-center " style={{ color: "white" }}>
                            <b>{val.candidato_id === "1" ? "0" : val.candidato_id - 100}</b>
                        </div>

                        <div className="bg-white py-3 px-3 d-flex align-items-center" style={{ width: "100%", height: "100%" }}>
                            <Avatar alt={val.candidato} src={`images/candidatos/${val.candidato}.jpg`} />
                            <small className="mx-3">{val.candidato}</small>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};

const VotacionView: React.FC = () => {
    const navigate = useNavigate();
    const [updating, setupdating] = useState(true);
    const [activa, setactiva] = useState(false);
    const [user, setuser] = useState<any | undefined>(undefined);
    const [votantes, setvotantes] = useState<any[]>([]);
    const [verified, setverified] = useState(false);
    
    useEffect(() => {
        const Verf = async () => {
            const seatnumber = localStorage.getItem("seatnumber");
            if(seatnumber){
                const data = {
                    seatnumber: seatnumber
                };
                await getLogBySeatNumber(data).then(v => {
                    if (v.data.successed) {
                        const user = localStorage.getItem("cn");
                        let tempUser;
                        if(user){
                            tempUser = JSON.parse(user);
                        }

                        if(v.data.delegate_id && v.data.delegate_id.length && tempUser && tempUser.dni === v.data.delegate_id[0].delegate_id.toString()){
                            //console.log('valido');
                        } else {
                            navigate("/curul");
                        }
                    }
                });
            }

            await VerificarActiva().then(v => {
                if (v.data.successed) {
                    const ac = localStorage.getItem("votacion");
                    if(ac && ac !== v.data.votacion.nombre){
                        window.location.reload();
                    }
                    localStorage.setItem("votacion", v.data.votacion.nombre);
                };
                setactiva(v.data.successed);
                setupdating(false);
            });
            const us = localStorage.getItem("cn");
            if (us) {
                const t = JSON.parse(us); setuser(t);
                getCongressmenHasVoted().then(r => {
                    if (r.data.successed) {
                        setvotantes(r.data.votantes.filter((vt: any) => { return vt.votante === t.dni }));
                    };
                    setverified(true);
                });
            };
        };

        const int = setInterval(() => {
            Verf();
        }, 3000);
        return () => clearInterval(int);

    }, [user, activa, updating]);

    if(!verified){
        return(
            <div className="container-fluid d-flex justify-content-center align-items-center" style={{height:"100vh"}} >
                <CircularProgress />
            </div>
        )
    };

    if(!activa){
        return(<WelcomeView user={user} />)
    }
    if(!(votantes.length > 0)){
        return(<Votacion onSave={() => { }} ind user={user} />)
    }
    return( <VotedView id={user.dni} user={user} /> )
};

export default VotacionDash;
