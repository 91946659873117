
import React, { useState } from "react";
import { postFile } from "../services/api";
import useFormHelper from "../helpers/useFormHelper";
import { Button } from "@mui/material";
import fileStyles from "../styles/Home.module.css";
import { comprimirImagen } from "../helpers/image";

interface IFileUp2{
    setImgRoute: (link: string) => void;
    handleClose: () => void;
}

const UploadImg: React.FC<IFileUp2> = ({setImgRoute, handleClose}) => {

    const [update, setUpdate] = useState(true);
    const [selected, setSelected] = useState([{file: ""}]);

    const states = useState({
        selectedFiles: null,
    });

    const {
        values,
        updateValues,
    } = useFormHelper(states);

    async function onFileChange(e: any) {
        updateValues({
            selectedFiles: e.target.files,
        });
        const lst : {file:string}[] = [];
        for(let element of e.target.files){
            lst.push({
                file: URL.createObjectURL(element)
            })
        };
        setSelected(lst);
        setUpdate(false);
    };

    const onUploadFiles = async () => {
        if (!update) {
            for(let element of values.selectedFiles){
                const formData = new FormData();
                const ename = element.name.replace(".", " ")
                const tag = new Date().getTime().toString();
                const blob = await comprimirImagen(element, 50);
                formData.append(
                    "file",
                    blob,
                    tag + " " + ename + ".jpeg"
                );
    
                formData.append('_method', 'PUT');
 
                await postFile(formData).then(v=>{
                    if(v.data.successed){
                        setImgRoute(`/public/imgs/${v.data.file}`);
                        handleClose();
                    }
                });
            };
        };
    };

    return (
        <div className="rounded-lg shadow text-center py-2 px-3 d-block">
            <div className="rounded form-group inputDnD py-2">
                <input
                    id="file"
                    type="file"
                    name="file"
                    accept="image/*"
                    className={fileStyles.file}
                    onChange={onFileChange}
                    data-title="Arrastra tu Imagen o haz click aqui."
                />
            </div>
            <div className="rounded-lg container-fluid">
                {(selected[0].file) && selected.map((val:any, index)=>
                    <img src={val.file} alt={index.toString()} key={index} width={50} />
                )}
            </div>
            <Button variant="contained" style={{marginTop:10}} onClick={onUploadFiles}>Subir</Button>
        </div>
    )
}

export default UploadImg;