import {useState, useEffect} from "react";
import { loginWithCard, getLogBySeatNumber } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { PathDefault } from '../../helpers/config';

const Curul: React.FC = () => {
    const navigate = useNavigate();
    const [show, setshow] = useState(false);
    
    useEffect(()=>{
        const inv = setInterval(()=>{
            setshow(false);
            
            const data = {
                seatnumber: localStorage.getItem("seatnumber")
            };
            getLogBySeatNumber(data).then(vd => {
                if(vd.data.delegate_id && vd.data.delegate_id.length){
                    loginWithCard(vd.data.delegate_id[0]).then(v => {
                        if (v.data.successed) {
                            const user = v.data.user;
                            localStorage.setItem("tk", v.data.key);
                            localStorage.setItem("us", JSON.stringify(v.data.user));
                            localStorage.setItem("cn", JSON.stringify(v.data.congresista));
                            const pathmodule = PathDefault.find(v=>{return v.rol === user.rol});
                            navigate(pathmodule? pathmodule.path: "/");
                        };
                    });
                }
            });            
        }, 1000);
        return ()=> clearInterval(inv);
    },[navigate, show]);

    if(!show){
        return(
            <div className="container-fluid d-flex align-items-center justify-content-center flex-column" style={{height:"100vh"}}>
                <img className="my-5" src="images/logo.png" alt="" height={250} />
                <h5><b>Ingrese Tarjeta...</b></h5>
            </div>
        )
    };
    return(
        <div></div>
    )
};

export default Curul;
