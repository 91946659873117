import axios from "axios";
import { query } from "./api";

axios.interceptors.request.use(
    config =>{
        const key = localStorage.getItem("tk");
        config.headers!.authorization = `Bearer ${key? key: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.Im9xaWVqb3F3ZWxwZXJyb2FtYXJpbGxvRFNGQUdTREdzZGdkcmhmYmRlMzQyNWxhZHJheWxhZHJhZHFkcXdkIHF3ZG5vbnF3aWV3bnFlcWVxZXRqaGs3NDMi.TCuZwTTp_D6fdJ0fi_C_n79b3FUniZIRQkEBtcfNhd3uAEup6jzvfyoj3LySoeDMpT2_ZuMJBSK4jwEKETBLsA"}`;
        return config;
    }, 
    error =>{
        return Promise.reject(error);
    }
);

export function newPoll(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/api/votaciones`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function addVotes(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/api/votos`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function updatePoll(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.put(`${query}/api/votaciones`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getPolls(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/votaciones`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getCongressmen(n: string = "", ban: string = "all"): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/congressmen/all/${ban}/${n}`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getOneCongressmen(id: string = ""): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/congressman/${id}`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getFilters(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/filters/congressmen`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};


export function getCongressmenHasVoted(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/votos/congresistas`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getVotos(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/votos`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getVotos2(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/v2/votos`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getDetallesVotosPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/detallesvotos`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function VerificarActiva(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/votaciones/activa`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getMag(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/candidatos`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getMagPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/candidatos`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getResultadosBancada(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/bancada/votos`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getCongressman(id: string ): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/v3/congressman/${id}`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};


export function ObtenerVotosPorCongresista(id: string ): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/votos/congresista/${id}`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function SendMail(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.post(`${query}/api/v2/votos`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getVotosVotacionActiva(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/votosvotacionactiva`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getVotosVotacionActivaPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/votosvotacionactiva`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getSeatCoordenadas(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/seatcoordenadas`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function updateCord(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.put(`${query}/api/updatecord`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getSeatCoordinateComplete(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/seatcoordinatecomplete`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getSeatCoordinateCompletePublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/seatcoordinatecomplete`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getSeatCoordinateDiagramaPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/seatcoordinatediagrama`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function updateCordComplete(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.put(`${query}/api/updatecordcomplete`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function updateCordDiagrama(data:any): Promise<any>{
    return new Promise<any>( resolve => {
        axios.put(`${query}/api/updatecorddiagrama`, data)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getCongresistas(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/shure/congresistas`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getCongresistasPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/shure/congresistas`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getLogDelegatesSeatNumberActiveConference(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/shure/logdelegatesseatnumberactiveconference`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getLogDelegatesSeatNumberActiveConferencePublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/shure/logdelegatesseatnumberactiveconference`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getVotingResultPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/shure/votingresultpublic`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getVotingResultRecientesPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/shure/votingresultrecientes`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};


export function getGruposBancada(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/api/gruposbancada`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getGruposBancadaPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/gruposbancada`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getOpcionesMagistrdosPublic(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/opciones`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};

export function getOpcionesSV4Public(): Promise<any>{
    return new Promise<any>( resolve => {
        axios.get(`${query}/public/shure/opciones`)
            .then(result => resolve(result) )
            .catch(error => resolve( {data: {successed:false, message: error.message}} ) );
    });
};



/*
CANCEL TOKEN CODE
let cancelTokenArray:{ [key: string]: any } = {};
let cancelToken = cancelTokenArray['getVotingResultRecientesPublic'];
console.dir(cancelTokenArray);
if (cancelToken) {
    cancelToken.cancel("Request cancelado"); 
}
cancelTokenArray['getVotingResultRecientesPublic'] = axios.CancelToken.source();
*/