import { useState, useEffect } from "react";
import { MagCardElegidosBG } from "../../components/cards";
import { getDetallesVotosPublic, getOpcionesMagistrdosPublic } from "../../services/juntanominadora";
import * as React from "react";

const ProyeccionElegidosBG: React.FC = () => {
    const [show, setshow] = useState(false);
    const [candidatos, setCandidatos] = useState<any[]>([]);
    const [upd, setupd] = useState(false);
    const [limite, setlimite] = useState<Number>(86); 
    const [orden, setorden] = useState<string>("candidato_id");    

    useEffect(() => {
        if (!upd) {
            setupd(true);
        }
        const inv = setInterval(() => {
            setshow(false);

            getOpcionesMagistrdosPublic().then((v) => {
                if (v.data.successed) {
                    const opciones = v.data.content;
                    const limite = opciones.find((o:any) => o.name === "limite");
                    const ordenOpc = opciones.find((o:any) => o.name === "orden");
                    setlimite(limite ? parseInt(limite.value) : 86);
                    setorden(ordenOpc ? ordenOpc.value : "candidato_id");
                };
            });

            getDetallesVotosPublic().then((v) => {
                if (v.data.successed) {
                    const votes = v.data.votos;
                    let candidatos: any[] = votes.filter((v: any) => { return (v.votos >= limite && v.candidato_id !== "1") });
                    if(orden === 'created_at') {
                        candidatos = 
                            candidatos.sort((a, b) => {
                                const dateA = a.created_at;
                                const dateB = b.created_at;
                                if (dateA < dateB) {
                                return -1;
                                }
                                if (dateA > dateB) {
                                return 1;
                                }
                                return 0;
                            });
                    }
                    setCandidatos(candidatos);
                };
            });
        }, 2000);
        return () => clearInterval(inv);
    }, [show, upd, limite, orden]);

    if (show) {
        return (
            <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                <h5><b>Esperando Resultados...</b></h5>
            </div>
        )
    };
    const startTop = 18.5;
    const startLeft = 18.95;
    const spaceLeft = 14;
    const spaceTop = 26.8;
    let lastHeight = (startTop + spaceTop * Math.max(Math.ceil(candidatos.length/5), 3));
    return (
        <div className="" style={{height: lastHeight+"vh", background: "#d0ebf3"}}>
            <img className="aling-right w-100 position-absolute start-0 h-100 m-0" src="images/magistrados_electos_bg.png" alt="Magistrados Electos" />
            <div className="d-flex align-items-center flex-column  justify-content-center">
                {candidatos.map((c, index) => {
                    let pos:number = index;
                    let i = 0;
                    let fila = 1;
                    const coorCandE = [
                        [startTop, startLeft + spaceLeft*(i++%5)], // 1
                        [startTop, startLeft + spaceLeft*(i++%5)], // 2
                        [startTop, startLeft + spaceLeft*(i++%5)], // 3
                        [startTop, startLeft + spaceLeft*(i++%5)], // 4
                        [startTop, startLeft + spaceLeft*(i++%5)], // 5
                        
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 6
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 7
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 8
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 9
                        [startTop + spaceTop * fila++, startLeft + spaceLeft*(i++%5)], // 10

                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 11
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 12
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 13
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 14
                        [startTop + spaceTop * fila++, startLeft + spaceLeft*(i++%5)], // 15

                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 16
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 17
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 18
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 18
                        [startTop + spaceTop * fila++, startLeft + spaceLeft*(i++%5)], // 18

                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 16
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 17
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 18
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 18
                        [startTop + spaceTop * fila++, startLeft + spaceLeft*(i++%5)], // 18

                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 16
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 17
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 18
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 18
                        [startTop + spaceTop * fila++, startLeft + spaceLeft*(i++%5)], // 18

                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 16
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 17
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 18
                        [startTop + spaceTop * fila, startLeft + spaceLeft*(i++%5)], // 18
                        [startTop + spaceTop * fila++, startLeft + spaceLeft*(i++%5)], // 18    
                    ];
                    if(pos >= coorCandE.length){
                        pos = coorCandE.length - 1;
                    }

                    const fontSize = ( Math.round((1 / c.nombre.lenght) * 100) ) + "vw";
                    return <MagCardElegidosBG key={index} name={c.nombre} top={coorCandE[pos][0]+"vh"} left={coorCandE[pos][1]+"vw"} width={"11vw"} height={"24vh"} fontSize={"1vw"} />;
                }
                
                )}
            </div>
        </div>
    )
};

export default ProyeccionElegidosBG;
