import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import b1 from "../imgs/logo.png";
import { getCongressman } from '../services/juntanominadora';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import moment from 'moment';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    line: {
        width: "100%",
        height: 0.5,
        margin: 2,
        backgroundColor: "black"
    },
    firmaLine: {
        width: "25%",
        height: 0.5,
        margin: 2,
        backgroundColor: "black"
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    container: {
        textAlign: "center"
    },
    title: {
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'Times-Roman',
        textDecoration: 'underline'
    },
    author: {
        fontSize: 10,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 12,
        margin: 12,
        textAlign: 'center',
        fontFamily: 'Times-Roman'
    },
    tema_title: {
        fontSize: 12,
        fontWeight: "bold",
        margin: 12,
        fontFamily: 'Times-Roman'
    },
    subtitle1: {
        fontSize: 10,
        fontFamily: 'Times-Roman',
        textAlign: "left",

    },
    tema: {
        fontSize: 12,
        margin: 12,
        fontFamily: 'Times-Roman'
    },
    text: {
        margin: 10,
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    text2: {
        margin: 10,
        fontSize: 8,
        textAlign: 'left',
        fontFamily: 'Times-Roman'
    },
    text5: {
        fontSize: 12,
        fontWeight: "extrabold",
        fontFamily: 'Times-Roman'
    },
    text3: {
        marginBottom: 5,
        fontSize: 8,
        fontWeight: "extrabold",
        fontFamily: 'Times-Roman'
    },
    text4: {
        marginBottom: 2,
        fontSize: 10,
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    image_header: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 10,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: "absolute",
        fontSize: 10,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

interface IRpd2 {
    r1: any[],
    r2: any[],
    r3: any[],
    date: string
};

export const ReportCongressman: React.FC<{ list: any[], congresista: string, fotoCongresista:string, formula:any, cargoCongresista:string, departamentoCongresista:string }> = ({ list, congresista, fotoCongresista, formula, cargoCongresista, departamentoCongresista}) => {

    let fotoCongresistaT:string = "";
    let formulaImage:string = "";

    try {
        fotoCongresistaT = require(`../imgs${fotoCongresista}`);
    } catch(e){
        fotoCongresistaT = require("../imgs/notfound_600x600.png");
    }

    try {
        if(formula){
            formulaImage = require(`../imgs${formula.img}`);
        }
    } catch(e){
        formulaImage = require("../imgs/notfound_600x600.png");
    }
    return (
        <Document>
            <Page size="LETTER" style={styles.body} >

                <View style={{ flexDirection: "column", alignItems: "center" }} fixed>
                    <Image
                        style={{ width: 150, marginBottom: 20 }}
                        src={b1}
                    />
                    <Text style={styles.title}>
                        CONGRESO NACIONAL DE LA REPÚBLICA DE HONDURAS 2022 - 2026
                    </Text>

                    <Text style={[styles.tema_title, {marginBottom: 2}]}>
                        INFORME DE VOTACIONES
                    </Text>
                    <Text style={[styles.subtitle1, { textAlign: "center" }]}>{localStorage.getItem("votacion")}</Text>
                    <Text style={[styles.subtitle1, { textAlign: "center", marginBottom: 10 }]}>H.C. {congresista ? congresista : 'DIPUTADO'}</Text>
                </View>

                <View style={styles.line} />
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ width: "75%", paddingLeft: 12 }}>
                        <Text style={[styles.subtitle1]}>ELECCIÓN DE MAGISTRADOS DE LA CORTE SUPREMA DE JUSTICIA</Text>
                    </View>
                    <View style={{ width: "25%" }}>
                        <Text style={[styles.subtitle1, {textAlign: 'right', paddingRight: 12}]}>{moment(new Date()).format("DD MMMM YYYY HH:mm:ss").toUpperCase()}</Text>
                    </View>
                </View>
                <View style={[styles.line, { marginBottom: 10 }]} />

                <View fixed>

                    <View style={[styles.text, { marginTop: 5, marginBottom: 7, flexDirection: "row" }]}>
                        <Text style={{ width: "10%", paddingRight: 10 }}>NO</Text>
                        <Text style={{ width: "90%", paddingRight: 10 }}>NOMBRE</Text>
                    </View>
                </View>

                {list.map((val, index) =>
                    <View key={index} style={[styles.text2, { marginTop: 4, marginBottom: 4, flexDirection: "row" }]} >
                        <Text style={{ width: "10%", paddingRight: 10 }}>{index + 1}</Text>
                        <Text style={{ width: "90%", paddingRight: 10 }}>{val.candidato}</Text>
                    </View>
                )}
                
                <View style={[styles.text2, { marginTop: 4, marginBottom: 4, flexDirection: "row" }]} >
                        <Text style={{ width: "100%", paddingRight: 10, textAlign: "center" }}>
                        ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: ÚLTIMA LINEA :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
                        </Text>
                </View>
                    
                <Text style={[styles.subtitle1, {marginTop: 15, marginBottom: 5, paddingLeft: 12 }]}>REGISTRO DE VOTACIONES REALIZADOS POR: </Text>
                <View style={{ display: "flex", flexDirection: "row", alignContent:"center", alignItems:"center", textAlign: "center"  }}>
                    <View style={{ 
                            width: "50px",
                            height: "50px",
                            borderStyle: "solid",
                            borderWidth: "2px"
                        }}>
                        <Image
                            style={{ 
                                width: "100%",
                            }}
                            src={fotoCongresistaT}
                        />
                    </View>
                    <View style={{ display: "flex", height: "50px", paddingLeft: 5, flexDirection: "column", justifyContent: "center" }}>
                        <Text style={[styles.subtitle1, { borderStyle: "solid", borderBottom: "2px"}]}>{congresista ? congresista : ''}</Text>
                        <Text style={[styles.subtitle1, { marginTop: 2 }]}>CONGRESISTA {cargoCongresista}</Text>
                        <Text style={[styles.subtitle1, { marginTop: 2}]}>{departamentoCongresista}</Text>
                    </View> 
                </View> 
                {cargoCongresista === "SUPLENTE" &&
                    <View>
                        <Text style={[styles.subtitle1, {marginTop: 15, marginBottom: 5, paddingLeft: 12 }]}>CONGRESISTA SUPLENTE DE: </Text>
                        <View style={{ display: "flex", flexDirection: "row", alignContent:"center", alignItems:"center", textAlign: "center"  }}>
                            <View style={{ 
                                    width: "50px",
                                    height: "50px",
                                    borderStyle: "solid",
                                    borderWidth: "2px"
                                }}>
                                <Image
                                    style={{ 
                                        width: "100%",
                                    }}
                                    src={formulaImage}
                                />
                            </View>
                            <View style={{ display: "flex", height: "50px", paddingLeft: 5, flexDirection: "column", justifyContent: "center" }}>
                                <Text style={[styles.subtitle1, { }]}>{formula.nombres} {formula.apellidos}</Text>
                                <Text style={[styles.subtitle1, { marginTop: 2 }]}>CONGRESISTA {formula.cargo}</Text>
                                <Text style={[styles.subtitle1, {marginTop: 2  }]}>{formula.departamento}</Text>
                            </View> 
                        </View> 
                    </View>
                }
                
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
}

export const ReportPdf2: React.FC<IRpd2> = ({ r1, r2, r3, date }) => {

    return (
        <Document>
            <Page size="LETTER" style={styles.body} >

                <View style={{ flexDirection: "column", alignItems: "center" }} fixed>
                    <Image
                        style={{ width: 150, marginBottom: 20 }}
                        src={b1}
                    />
                    <Text style={styles.title}>
                        CONGRESO NACIONAL DE LA REPÚBLICA DE HONDURAS 2022 - 2026
                    </Text>

                    <Text style={[styles.tema_title, {}]}>
                        INFORME TOTAL DE VOTACIONES
                    </Text>
                </View>

                <View style={styles.line} />
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={{ width: "80%" }}>
                        <Text style={[styles.subtitle1]}>ELECCIÓN DE MAGISTRADOS DE LA CORTE SUPREMA DE JUSTICIA</Text>
                    </View>
                    <View style={{ width: "20%" }}>
                        <Text style={[styles.subtitle1]}>{date}</Text>
                    </View>
                </View>
                <View style={[styles.line, { marginBottom: 10 }]} />

                <View fixed>
                    <Text style={[styles.subtitle1, { textAlign: "center" }]}>VOTOS POR CONGRESISTA</Text>

                    <View style={[styles.text, { marginTop: 5, marginBottom: 7, flexDirection: "row" }]}>
                        <Text style={{ width: "10%", paddingRight: 10 }}>NO</Text>
                        <Text style={{ width: "90%", paddingRight: 10 }}>NOMBRE</Text>
                    </View>
                </View>

                {r1.map((val, index) =>
                    <RElem val={val} r2={r2} r3={r3} index={index} />
                )}

                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
}

const RElem: React.FC<{ val: any, r2: any[], r3: any[], index: number }> = ({ val, r2, r3, index }) => {
    const votaciones: any[] = r2.filter((v) => v.user_id === val.user_id);
    const [upd, setupd] = useState(false);
    const [congressman, setcongressman] = useState<any | undefined>(undefined);

    const { id }: any = useParams();

    useEffect(() => {
        if (!upd && id) {
            setupd(true);
            getCongressman(id).then(v => {
                if (v.data.successed) {
                    setcongressman(v.data.congresista);
                };
            });
        };
    }, [upd, id]);

    return (
        <View key={val.user_id} style={[styles.text2, { marginTop: 4, marginBottom: 4, flexDirection: "row" }]} >
            <Text style={{ width: "10%", paddingRight: 10 }}>{index + 1}</Text>
            <View style={{ width: "90%", paddingRight: 10 }}>
                <Text style={styles.text5}>{congressman ? congressman.nombres : val.user_id}</Text>
                <View style={styles.line} />
                {
                    votaciones.map((e) => {
                        const candidatos: any[] = r3.filter((c) => (c.votacion_id === e.votacion_id && c.user_id === e.user_id))
                        return (
                            <View key={e.votacion_id} style={{ marginBottom: 10, marginTop: 10 }}>
                                <Text style={styles.text3}>{e.nombre.toUpperCase()}:</Text>
                                {candidatos.map((candidato) =>
                                    <Text key={candidato.candidato_id} style={styles.text3}>{candidato.nombre.toUpperCase()}</Text>
                                )}
                            </View>
                        )
                    })
                }
            </View>
        </View>
    )

}


interface IRpd {
    list: any[],
    date: string,
    rounds: number,
    total: number,
    mainGraph?: any
};

const ReportPdf: React.FC<IRpd> = ({ list, date, rounds, total, mainGraph }) => {

    return (
        <Document>
            <Page size="LETTER" style={styles.body} >

                <View style={{ flexDirection: "column", alignItems: "center" }} fixed>
                    <Image
                        style={{ width: 150, marginBottom: 20 }}
                        src={b1}
                    />
                    <Text style={styles.title}>
                        CONGRESO NACIONAL DE LA REPÚBLICA DE HONDURAS 2022 - 2026
                    </Text>

                    <Text style={[styles.tema_title, {}]}>
                        INFORME TOTAL DE VOTACIONES
                    </Text>
                </View>
                <View>
                    <View style={styles.line} />
                    <View style={{ display: "flex", flexDirection: "row" }}>
                        <View style={{ width: "80%" }}>
                            <Text style={[styles.subtitle1]}>ELECCIÓN DE MAGISTRADOS DE LA CORTE SUPREMA DE JUSTICIA</Text>
                        </View>
                        <View style={{ width: "20%" }}>
                            <Text style={[styles.subtitle1]}>{date}</Text>
                        </View>
                    </View>
                    <View style={styles.line} />

                    <View style={{ marginHorizontal: 15, marginVertical: 10 }}>
                        <Text style={[styles.subtitle1]}>Registro general de votaciones</Text>
                        <View style={{ marginHorizontal: 40, marginTop: 5, marginBottom: 15 }}>
                            <Text style={[styles.subtitle1]}>Rondas de votaciones: {rounds}</Text>
                            <Text style={[styles.subtitle1]}>Total de candidatos: {total}</Text>
                        </View>
                        <Text style={[styles.subtitle1, { textAlign: "center" }]}>GRAFICO COMPARATIVO</Text>
                    </View>

                    {mainGraph && (
                        <Image
                            style={{ marginBottom: 20 }}
                            src={mainGraph}
                        />
                    )}

                    <View style={{}}>
                        <Text style={[styles.subtitle1, { textAlign: "center" }]}>CANDIDATOS SELECCIONADOS</Text>

                        <View style={[styles.text, { marginTop: 5, marginBottom: 7, flexDirection: "row" }]} fixed >
                            <Text style={{ width: "10%", paddingRight: 10 }}>No</Text>
                            <Text style={{ width: "80%", paddingRight: 10 }}>NOMBRE</Text>
                            <Text style={{ width: "10%", paddingRight: 10 }}>VOTOS</Text>
                        </View>

                        {list.map((val, index) =>
                            <View key={index} style={[styles.text2, { marginTop: 4, marginBottom: 4, flexDirection: "row" }]} >
                                <Text style={{ width: "10%", paddingRight: 10 }}>{index + 1}</Text>
                                <Text style={{ width: "80%", paddingRight: 10 }}>{val.nombre}</Text>
                                <Text style={{ width: "10%", paddingRight: 10 }}>{val.votos}</Text>
                            </View>
                        )}
                    </View>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
}

export default ReportPdf;


export const PDFReporteVotosIndividualPapeleta: React.FC<{ candidatos:any, votacionNumber:number, congresista:string, votos:any, puestos:any, image:string, user:any, formula?:any}> = ({ candidatos, votacionNumber, congresista, votos, puestos, image, user, formula }) => {
    
    const votoEnBlanco = votos.filter( (v:any) => v.candidato_id === "1");
    const esVotoEnBlanco = votoEnBlanco.length === 1 ? true : false;
    let image2:string = "";
    let formulaImage:string = "";

    try {
        image2 = require(`../imgs${image}`);
    } catch(e){
        image2 = require("../imgs/notfound_600x600.png");
    }
    
    try {
        if(formula){
            formulaImage = require(`../imgs${formula.img}`);
        }
    } catch(e){
        formulaImage = require("../imgs/notfound_600x600.png");
    }
    
    return (        
        <Document>
            <Page size="TABLOID" orientation='landscape' style={{
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                paddingHorizontal: 0,
            }} >
                <View>
                    <Image
                        style={{ width: "100vw", height: "auto", position: "absolute", top: "0px", left: "0px" }}
                        src={require("../imgs/papeletas/papeleta_individual.png")}
                    />
                    <Text 
                    style={{
                        fontSize: "2.4vw",
                        fontFamily: "Helvetica-Bold",
                        position: "absolute",
                        top: `12.3vh`,
                        left: `17.56vw` 
                    }}>{votacionNumber}</Text>
                    
                    <Text 
                    style={{
                        fontSize: "1.8vw",
                        fontFamily: "Helvetica-Bold",
                        position: "absolute",
                        top: `13.1vh`,
                        left: `78vw` 
                    }}>{esVotoEnBlanco ? "00" : votos.length < 9 ? "0" + votos.length : votos.length} {puestos}</Text>

                    {candidatos.map((candidato:any, index:number) => {
                        const position = index - 1;
                        if(index !== 0) {
                            const voto = votos.filter( (v:any) => v.candidato_id === candidato.id);
                            return (
                                <Text key={position} 
                                style={{
                                    fontSize: "5vw",
                                    fontFamily: "Helvetica-Bold",
                                    position: "absolute",
                                    top: `${ (Math.floor(position/15) * 23.5) + 31.5}vh`,
                                    left: `${ ((position%15) * 6.38) + 3.75}vw` 
                                }}>{voto.length ? "X" : ""}</Text>
                            )
                        }
                        return <Text key={position}></Text>
                    }                    
                    )}

                    <Text 
                    style={{
                        fontSize: "1.2vw",
                        position: "absolute",
                        top: `91.6vh`,
                        left: `13.9vw` 
                    }}>{moment(new Date()).format("DD MMMM YYYY HH:mm:ss").toUpperCase()}</Text>

                    <View 
                        style={{ 
                            position: "absolute",
                            top: `88vh`,
                            left: `30.5vw`,
                            width: "4.9vw",
                            height: "4.9vw",
                            borderStyle: "solid",
                            borderWidth: "2px"
                        }}>
                        <Image
                            style={{ 
                                width: "100%",
                            }}
                            src={image2}
                        />
                    </View>

                    <Text 
                    style={{
                        fontSize: "1vw",
                        position: "absolute",
                        top: "88vh",
                        left: "36vw",
                    }}>VOTACIÓN REALIZADA POR:</Text>
                    
                    {user.cargo === "SUPLENTE" &&
                        <View 
                        style={{
                            fontSize: "1vw",
                            position: "absolute",
                            top: "88vh",
                            left: "36vw",
                            width: "56.1vw",
                            textAlign: "center",
                            display: "flex", 
                            flexDirection: "row",
                            justifyContent: "center",
                        }}>
                            <Text style={{fontSize: "1.3vw",opacity: 0}}>{congresista}</Text>
                            <Text> SUPLENTE DE </Text>
                            <Text style={{fontSize: "1.3vw",opacity: 0}}>{formula.nombres} {formula.apellidos}</Text>
                        </View>
                    }

                    <Text 
                    style={{
                        fontSize: "1.3vw",
                        fontFamily: "Helvetica-Bold",
                        position: "absolute",
                        top: "89.8vh",
                        left: "36vw",
                        borderStyle: "solid",
                        borderBottom: "2px",
                    }}>{congresista}</Text>

                    
                    <Text 
                    style={{
                        fontSize: "1vw",
                        position: "absolute",
                        top: "92.5vh",
                        left: "36vw",
                    }}>CONGRESISTA {user.cargo}</Text>

                    <Text 
                    style={{
                        fontSize: "0.8vw",
                        position: "absolute",
                        top: "94.3vh",
                        left: "36vw",
                    }}>{user.departamento}</Text>

                    {formula && user.cargo === "SUPLENTE" &&
                        <View>
                            <View 
                                style={{ 
                                    position: "absolute",
                                    top: "88vh",
                                    right: "2.4vw",
                                    width: "4.9vw",
                                    height: "4.9vw",
                                    borderStyle: "solid",
                                    borderWidth: "2px"
                                }}>
                                <Image
                                    style={{ 
                                        width: "100%",
                                    }}
                                    src={formulaImage}
                                />
                            </View>

                            <Text 
                            style={{
                                fontSize: "1.3vw",
                                fontFamily: "Helvetica-Bold",
                                position: "absolute",
                                top: "89.8vh",
                                right: "7.9vw",
                                borderStyle: "solid",
                                borderBottom: "2px"
                            }}>{formula.nombres} {formula.apellidos}</Text>

                            <Text 
                                style={{
                                    fontSize: "1vw",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    top: "92.5vh",
                                    right: "7.9vw",
                                }}>CONGRESISTA {formula.cargo}
                            </Text>
                            
                            <Text 
                                style={{
                                    fontSize: "0.8vw",
                                    position: "absolute",
                                    top: "94.3vh",
                                    right: "7.9vw",
                                }}>{formula.departamento}
                            </Text>
                        </View>
                    }
                </View>
            </Page>
        </Document>
    )
}