import { Dashboard, MeetingRoom, Note, Person } from "@mui/icons-material";
import React from "react";
import MainContainer from "../components/container";
import PersistentDrawer, { DrawnerElement, DrawnerLink } from "../components/persistenDrawer";


const Home: React.FC = () => {
    return (
        <MainContainer name="Principal">
            <UsuariosPanel />
        </MainContainer>
    )
};

const UsuariosPanel: React.FC = () => {
    const elements: DrawnerElement[] = [
        { title: "Administrador", label: "Administrador", view: <div></div> },
    ];
    const links: DrawnerLink[] = [
        { label: "Comunicaciones", to:"/comunicaciones", icon: <Note /> },
        { label: "Magistrados", to:"/magistrados", icon: <Dashboard /> },
        { label: "Usuarios", to:"/usuarios", icon: <Person /> },
        { label: "Reuniones", to:"/reuniones", icon: <MeetingRoom /> },
    ];
    const [selected, setselected] = React.useState<DrawnerElement>(elements[0]);
    function onSavedPost(){
        setselected(elements[1]);
    };
    return(
        <PersistentDrawer selected={selected} setselected={setselected} elements={elements} links={links}></PersistentDrawer>
    )
}

export default Home;