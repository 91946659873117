import { Search } from "@mui/icons-material";
import { Button, CircularProgress, Pagination } from "@mui/material";
import { useState, useEffect } from "react";
import { CmCard } from "../../components/cards";
import { FormSelect } from "../../components/MUIComponents";
import { getCongressmen, getCongressmenHasVoted, getFilters, getResultadosBancada } from "../../services/juntanominadora";
import stylesVotaciones from "../../styles/votacionStyles.module.css"

interface ReView {
    inColumns?: boolean,
    loop?: boolean,
}
export const ResultsView: React.FC<ReView> = ({ inColumns, loop }) => {
    const [upd, setupd] = useState(false);
    const [updating, setupdating] = useState(true);
    const [results, setresults] = useState<any[]>([]);
    const [tparticipantes, settp] = useState(0);

    useEffect(() => {
        if (!loop) {
            if (!upd) {
                setupd(true);
                getResultadosBancada().then(t => {
                    if (t.data.successed) {
                        setresults(t.data.resultados);
                        settp(t.data.total)
                    };
                    setupdating(false)
                });
            };
        } else {
            const int = setInterval(() => {
                getResultadosBancada().then(t => {
                    if (t.data.successed) {
                        setresults(t.data.resultados);
                        settp(t.data.total)
                    };
                    setupdating(false)
                });
            }, 1000);
            return () => clearInterval(int);
        }
    }, [upd, results, tparticipantes, loop]);

    if (updating) {
        return (
            <div className="container-fluid text-center py-2">
                <CircularProgress />
            </div>
        )
    }
    if (results.length === 0) {
        return (
            <div className="container-fluid text-center">
                <h6><b>No hay votacion en curso</b></h6>
            </div>
        )
    }
    return (
        <div>
            <hr className="my-1" />
            <div className={`${inColumns ? "row" : "d-flex"} align-items-center justify-content-center`}>
                {results.map((result, idx) =>
                    <div key={idx} className={`${inColumns ? "col-md-12" : ""} mx-2`}  >
                        <small style={{ fontSize: 12 }}><b>{result.bancada ? result.bancada : "ESCANEADOS"}: </b></small>
                        <small style={{ fontSize: 14 }}>{result.votos} / {result.total ? result.total : 128}</small>
                    </div>
                )}
            </div>
            <hr className="my-1" />
            <div className="d-flex justify-content-end py-1 d-none">
                <small><b>Total de participantes: </b>{tparticipantes}</small>
            </div>
        </div>
    )
}

const Votantes: React.FC<{ onClickElement: () => void; }> = ({ onClickElement }) => {

    const [congressmen, setcongressmen] = useState<any[]>([]);
    const [votantes, setvotantes] = useState<any[]>([]);
    const [upd, setupd] = useState(false);

    const [updating, setupdating] = useState(true);
    const [n, setn] = useState("");
    const [limit] = useState(12);
    const [page, setPage] = useState(1);
    const [ban, setban] = useState("all");
    const [bans, setbans] = useState<string[]>([]);

    const sc = (n: string, ban: string = "all") => {
        getCongressmen(n, ban).then(v => {
            if (v.data.successed) {
                setcongressmen(v.data.congresistas);
            };
            setupdating(false);
            setPage(1);
        });
    };

    function search() {
        sc(n);
    };

    function onEnter(e: any) {
        if (e.code === "Enter") {
            sc(n);
        };
    };

    function onChangeText(e: any) {
        const txt = e.target.value;
        sc(txt);
    }

    useEffect(() => {
        if (!upd) {
            setupd(true);
            setupdating(true);
            localStorage.removeItem("congresista");
            getFilters().then(p => {
                if (p.data.successed) {
                    setbans(p.data.bancadas)
                };
            });
            getCongressmenHasVoted().then(r => {
                if (r.data.successed) {
                    setvotantes(r.data.votantes);
                }
            });

            getCongressmen().then(v => {
                if (v.data.successed) {
                    setcongressmen(v.data.congresistas)
                };
                setupdating(false);
            });

        };
    }, [upd, congressmen, votantes, n, bans, ban]);

    return (
        <div className="">

            <div className="row pb-3 px-2 shadow-sm rounded mb-3 align-items-center">
                <div className="col-lg-5 d-flex align-items-center">
                    <input className="form-control" placeholder="Busqueda" onKeyDown={onEnter} onChange={(e) => { setn(e.target.value); onChangeText(e) }} />
                    <Button variant="contained" className="mx-2 d-none" onClick={search}><Search /></Button>
                </div>
                <div className="col-lg-4 d-flex align-items-center justify-content-center px-4">
                    <FormSelect value={ban} label={"Bancada"} onChangeValue={(e) => { setban(e.target.value); sc(n, e.target.value) }} elements={[]} secondary={bans} name="ban" defaultValue="all" />
                </div>
                <div className={"col-lg-3 d-flex align-items-center justify-content-end " + stylesVotaciones.flexdColumn}>
                    <small className="mx-3"><b>MOSTRANDO: </b>{congressmen.length} / 256</small>
                    <h6 className={stylesVotaciones.selectFloat}><b>VOTOS RECIBIDOS: {votantes.length}</b></h6>
                </div>
            </div>
            <ResultsView />
            {!updating && (
                <div className="py-2">
                    <div className="row">
                        {congressmen.map((val: any, idx) =>
                            ((idx < limit * page) && (idx >= (page - 1) * limit)) &&
                            <CmCard cargo={val.cargo} formula={val.formula} email={val.email} disabled={votantes.filter(v => { return v.votante === val.dni }).length > 0} onClickElement={onClickElement} key={val.dni} bancada={val.bancada} id={val.dni} nombre={`${val.nombres} ${val.apellidos}`} departamento={val.departamento} descripcion={val.descripcion} imagen={val.img.normalize("NFD").replace(/[\u0300-\u036f]/g, "")} />
                        )}
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <Pagination count={Math.ceil(congressmen.length / limit)} page={page} onChange={(e, val) => { setPage(val); }} />
                    </div>
                </div>
            )}
            {updating && (
                <div className="text-center">
                    <CircularProgress />
                </div>
            )}
        </div>
    )

};


export default Votantes;