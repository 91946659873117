import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'moment/locale/es';

import Home from './pages/home';
import Login from './pages/login';
import MeetingDetails from "./pages/salones/meetingDetails";
import PDetails from "./pages/comunicaciones/postDetails";
import UsersDetails from "./pages/usuarios/usersDetails";
import PostEditor from "./pages/comunicaciones/postEditor";
import Comunicaciones from "./pages/comunicaciones/comunicaciones";
import Usuarios from "./pages/usuarios/usuarios";
import Reuniones from "./pages/salones/reuniones";
import Magistrados from "./pages/juntanominadora/PanelUrna";
import PanelMagistrados from "./pages/juntanominadora/PanelAdministrador";
import Perfiles from "./views/juntanominadora/perfiles";
import VotacionDash from "./pages/juntanominadora/PanelCongresista";
import Proyeccion from "./views/juntanominadora/proyeccion";
import ProyeccionElegidos from "./views/juntanominadora/proyeccionElegidos";
import Curul from "./views/juntanominadora/curul";
import DiagramaSimple from "./views/juntanominadora/diagramaSimple";
import DiagramaCompleto from "./views/juntanominadora/diagramaCompleto";
import ReportePrueba from "./views/juntanominadora/reporteprueba";
import ProyeccionVotos from "./views/juntanominadora/proyeccionVotos";
import ProyeccionElegidosBG from "./views/juntanominadora/proyeccionElegidosBG";
import DiagramaCompletoVotos from "./views/juntanominadora/diagramaCompletoVotos";
import DiagramaCompletoImpresion from "./views/juntanominadora/diagramaCompletoImpresion";
import DiagramaCompletoVotosImpresion from "./views/juntanominadora/diagramaCompletoVotosImpresion";
import DiagramaCompletoVotosSistemaCSJ from "./views/juntanominadora/diagramaCompletoVotosSistemaCSJ";
import Diagrama from "./views/juntanominadora/diagrama";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<Home />} />
          <Route path="login">
            <Route index element={<Login />} />
          </Route>
          <Route path="votaciones">
            <Route index element={<Magistrados/>} />
          </Route>
          <Route path="proyeccionvotos">
            <Route index element={<ProyeccionVotos/>} />
          </Route>
          <Route path="proyeccion">
            <Route index element={<Proyeccion/>} />
          </Route>
          <Route path="proyeccionelegidos">
            <Route index element={<ProyeccionElegidos/>} />
          </Route>
          <Route path="proyeccionelegidosbg">
            <Route index element={<ProyeccionElegidosBG/>} />
          </Route>
          <Route path="diagrama">
            <Route index element={<Diagrama/>} />
          </Route>
          <Route path="asistencia">
            <Route index element={<Diagrama onlyAsistencia={true}/>} />
          </Route>
          <Route path="diagramasimple">
            <Route index element={<DiagramaSimple/>} />
          </Route>
          <Route path="diagramacompleto">
            <Route index element={<DiagramaCompleto/>} />
          </Route>
          <Route path="diagramacompletoimpresion">
            <Route index element={<DiagramaCompletoImpresion/>} />
          </Route>
          <Route path="diagramacompletovotos">
            <Route index element={<DiagramaCompletoVotos/>} />
          </Route>
          <Route path="diagramacompletovotosimpresion">
            <Route index element={<DiagramaCompletoVotosImpresion/>} />
          </Route>
          <Route path="diagramacompletovotossistemacsj">
            <Route index element={<DiagramaCompletoVotosSistemaCSJ/>} />
          </Route>
          <Route path="diagramacompletovotossistemacsjimpresion">
            <Route index element={<DiagramaCompletoVotosSistemaCSJ/>} />
          </Route>
          <Route path="curul">
            <Route index element={<Curul/>} />
          </Route>
          <Route path="reporteprueba">
            <Route index element={<ReportePrueba/>} />
          </Route>
          <Route path="votacion">
            <Route index element={<VotacionDash />} />
          </Route>
          <Route path="perfiles/:id">
            <Route index element={<Perfiles/>} />
          </Route>
          <Route path="magistrados">
            <Route index element={<PanelMagistrados/>} />
          </Route>
          <Route path="comunicaciones">
            <Route index element={<Comunicaciones />} />
          </Route>
          <Route path="usuarios">
            <Route index element={<Usuarios />} />
          </Route>
          <Route path="reuniones">
            <Route index element={<Reuniones />} />
          </Route>
          <Route path="/publicaciones/:id" >
            <Route index element={<PDetails />} />
          </Route>
          <Route path="/editar/publicaciones/:id" >
            <Route index element={<PostEditor />} />
          </Route>
          <Route path="/reuniones/:id" >
            <Route index element={<MeetingDetails />} />
          </Route>
          <Route path="/usuarios/:id" >
            <Route index element={<UsersDetails />} />
          </Route>
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
