import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { PathDefault } from '../helpers/config';
import cn from "../imgs/logo.png";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://congresonacional.hn/">
        Congreso Nacional de Honduras
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const seatnumber = formData.get('curul');

    localStorage.removeItem("tk"); // token
    localStorage.removeItem("us"); // user
    localStorage.removeItem("cn"); // congresista
    localStorage.removeItem("seatnumber"); // login con tarjeta
    localStorage.removeItem("congresista"); // seleccionado centro de votacion
    localStorage.removeItem("votacion"); // votacion

    if(seatnumber) {
      localStorage.setItem("seatnumber", seatnumber.toString());
      navigate("/curul");
      return;
    }
    
    const ld = {
      user: formData.get('email'),
      pass: formData.get('password'),
    };

    login(ld).then(v => {
      if (v.data.successed) {
        localStorage.setItem("tk", v.data.key);
        localStorage.setItem("us", JSON.stringify(v.data.user));
        localStorage.setItem("cn", JSON.stringify(v.data.congresista));

        const user = v.data.user;
        const pathmodule = PathDefault.find(v=>{return v.rol === user.rol});
        navigate(pathmodule? pathmodule.path: "/");
      } else {
        alert("Error al iniciar sesion");
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <img className="my-3" src={cn} alt="cn" width={250}/>

          <Typography component="h1" variant="h5">
           
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Usuario"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          <Typography component="h1" variant="h5" textAlign={"center"}>
           ó
          </Typography>
            
            <TextField
              margin="normal"              
              fullWidth
              name="curul"
              label="Curul"
              id="curul"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar Sesion
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}