import { Save } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import React from "react";
import { useEffect, useState } from "react";
import { MagCard } from "../../components/cards";
import { PDFReporteVotosIndividualPapeleta, ReportCongressman } from "../../components/Reportes";
import { addVotes, getMag, getOneCongressmen, getOpcionesMagistrdosPublic, getVotos, ObtenerVotosPorCongresista, SendMail } from "../../services/juntanominadora";
import stylesVotaciones from "../../styles/votacionStyles.module.css"


const Votacion: React.FC<{ onSave: () => void, ind?: boolean, user?: any|undefined }> = ({ onSave, ind, user }) => {

    const [selected, setselected] = useState<string[]>([]);
    const [puestos, setpuestos] = useState(0);
    const [electos, setelectos] = useState<any[]>([]);
    const [candidatos, setcandidatos] = useState<any[]>([]);
    const [formula, setformula] = useState<any>({});
    const [limite, setlimite] = useState<Number>(86);
    const [upd, setupd] = useState(false);


    function addMag(mg: string, disabled: boolean) {
        if (!disabled) {
            const t = selected.filter(v => { return v === mg });
            if (t.length === 0) {
                if (selected.length < puestos) {
                    setselected([...selected, mg]);
                } else {
                    alert(`Ya ha seleccionado el maximo de ${puestos} candidatos`)
                }
            } else {
                const l = selected.filter(v => { return v !== mg });
                setselected([...l]);
            }
        };
    };

    function save() {
        const ustemp = localStorage.getItem("congresista") ? localStorage.getItem("congresista") : localStorage.getItem("cn");
        if (ustemp) {
            const us = JSON.parse(ustemp);
            const cntemp = localStorage.getItem("congresista") ? { id: us.id, name: us.nombre, email: us.email, formula: us.formula } : { id: us.dni, name: `${us.nombres} ${us.apellidos}`, email: us.email, formula: us.formula }
            if (selected.length <= puestos) {
                const t = selected.length > 0;
                addVotes({ votes: t ? selected : ["1"], user: localStorage.getItem("congresista") ? us.id : us.dni, group: us.bancada, seatnumber: localStorage.getItem("seatnumber")}).then(async v => {
                    if (v.data.successed) {
                        alert(`Su voto${t ? "" : " en blanco"} ha sido registrado con exito, gracias por su participacion.`);
                        await ObtenerVotosPorCongresista(cntemp.id).then(async v => {
                            if (v.data.successed) {
                                const votacion = localStorage.getItem("votacion")
                                if (votacion) {
                                    const votosIndividuales = v.data.activa;
                                    const votacionNumber = votosIndividuales[0] ? votosIndividuales[0].votacion_id : 0;
                                    /* Reporte Papeleta*/
                                    const pdfReporteVotosIndividualPapeleta = await pdf(<PDFReporteVotosIndividualPapeleta formula={formula} candidatos={candidatos} votacionNumber={votacionNumber} congresista={cntemp.name} votos={votosIndividuales} puestos={puestos} image={user.img} user={user}/>).toBlob();
                                    const a = document.createElement('a')
                                    a.href = URL.createObjectURL(pdfReporteVotosIndividualPapeleta)
                                    a.download = `${cntemp.name}_${votacion}_papeleta`.replace(/\s+/g, '_').toUpperCase();
                                    document.body.appendChild(a)
                                    a.click()
                                    document.body.removeChild(a) 

                                    /* Reporte Standar */
                                    const reporteVotacionStandar = await pdf(<ReportCongressman list={votosIndividuales} formula={formula} congresista={cntemp.name} cargoCongresista={user.cargo} fotoCongresista={user.img} departamentoCongresista={user.departamento}/>).toBlob();
                                    const a2 = document.createElement('a')
                                    a2.href = URL.createObjectURL(reporteVotacionStandar)
                                    a2.download = `${cntemp.name}_${votacion}`.replace(/\s+/g, '_').toUpperCase();
                                    document.body.appendChild(a2)
                                    a2.click()
                                    document.body.removeChild(a2)

                                    /*
                                    const reader = new FileReader();

                                    reader.readAsDataURL(t);
                                    reader.onloadend = function () {
                                        const base64data = reader.result;
                                        SendMail({
                                            att: base64data,
                                            poll: votacion,
                                            name: cntemp.name,
                                            email: cntemp.email
                                        })
                                    }
                                    */
                                }

                            };
                        });
                        onSave();
                    } else {
                        alert(v.data.message);
                    };
                });
            } else {
                alert(`Debe seleccionar un total de ${puestos} candidatos`)
            }
        } else {
            alert("Primero debe seleccionar un congresista")
        }
    };

    const getCards = () => {
        const content = [];
        for (let i = 0; i < 3; i++) {
            const elements = [];
            for (let j = 0; j < 15; j++) {
                const idx: number = j + (15 * i)
                const posttemp = candidatos.filter((val) => { return val.id !== "1" })
                const cand: any = posttemp[idx];

                if (cand) {
                    const l = electos.filter(v => { return v.candidato_id === cand.id });

                    elements.push(
                        <MagCard key={cand.id} position={idx + 1} mark={"X"} name={cand.nombre} disabled={l.length > 0} selected={selected.filter(v => { return v === cand.id }).length === 1} setselected={() => { addMag(cand.id, l.length > 0) }} />
                    );
                } else {
                    elements.push(
                        <MagCard key={`${i}-${j}`} position={idx + 1} mark={"X"} name={"Cargando..."} disabled={true} selected={selected.filter(v => { return v === `${i}-${j}` }).length === 1} setselected={() => { addMag(`${i}-${j}`, true) }} />
                    );
                };
            };

            let mainContainer = React.createElement("div", { className: "row mx-auto my-2", key: "row" + i }, elements);
            content.push(mainContainer);
        };
        return content;
    }

    useEffect(() => {
        if (!upd) {
            setupd(true);

            getMag().then(v => {
                if (v.data.successed) {
                    const mag: any[] = v.data.candidatos;
                    setcandidatos(mag)
                };
            });

            getOpcionesMagistrdosPublic().then((op) => {
                if (op.data.successed) {
                    const opciones = op.data.content;
                    const limiteOpc = opciones.find((o:any) => o.name === "limite");
                    const limite = limiteOpc ? parseInt(limiteOpc.value) : 86;
                    setlimite(limite);

                    getVotos().then(v => {
                        if (v.data.successed) {
                            const candidatosElectos = v.data.votos.filter((v: any) => { return (v.votos >= limite && v.candidato_id !== "1") });
                            setpuestos(15 - candidatosElectos.length);
                            setelectos(candidatosElectos);
                        };
                    });
                };
            });

            
            
            getOneCongressmen(user.formula).then(v => {
                if (v.data.successed) {
                    setformula(v.data.congresista)
                };
            });
        }
    }, [upd, puestos, candidatos, electos, selected, formula, user.formula, limite]);

    return (
        <div className={`${stylesVotaciones.cardPaddin} ${ind ? '': stylesVotaciones.cardPaddinCentroVotacion} px-0 text-center overflow-hidden`}>
            <div className={`${stylesVotaciones.topBarCongresista} ${ind ? '': 'd-none'}`}>
                <Avatar className="" alt={`${user.nombres} ${user.apellidos}`} src={`images/${user.img}`} sx={{ width: "40px", height: "40px" }} />
                <p className="mx-3 m-0">H.D. {user.nombres} {user.apellidos}</p>
            </div>
            <div className={stylesVotaciones.selectFloat}>
                <span>Electos: {electos.length}</span>&emsp;&emsp;&emsp;
                <span>Seleccionados: {selected.length} / {puestos}</span>
            </div>
            <div>
                {getCards()}
            </div>
            <Button variant="contained" disabled={!(selected.length <= puestos)} className="mt-1" onClick={save} startIcon={<Save />} >VOTAR</Button>
        </div>
    )

};

export default Votacion;
