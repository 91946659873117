import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';

export function DenseMenu() {
    return (
        <Paper sx={{ width: 320 }}>
            <MenuList dense>
                <MenuItem>
                    <ListItemText inset>Single</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemText inset>1.15</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemText inset>Double</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    Custom: 1.2
                </MenuItem>
                <Divider />
                <MenuItem>
                    <ListItemText>Add space before paragraph</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemText>Add space after paragraph</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem>
                    <ListItemText>Custom spacing...</ListItemText>
                </MenuItem>
            </MenuList>
        </Paper>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface TabProps {
    label: string | React.ReactElement,
    children: React.ReactElement
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                        <Typography component="span">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface BasicTabsProps {
    tabs: TabProps[]
}

export default function BasicTabs(props: BasicTabsProps) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {props.tabs.map((v, index) =>
                        <Tab key={index} label={v.label} {...a11yProps(index)} />
                    )}
                </Tabs>
            </Box>
            {props.tabs.map((v, index) =>
                <TabPanel  key={index} value={value} index={index}>
                    {v.children}
                </TabPanel>
            )}
        </Box>
    );
}





export interface SelectItem {
    label: string,
    value: string;
};

interface IFormSelect {
    value?: string,
    label: string,
    name: string,
    elements: SelectItem[],
    secondary?: string[],
    defaultValue?: string,
    onChangeValue: (val: any) => void;
    className?: string
};

export const FormSelect: React.FC<IFormSelect> = ({ value, label, elements, secondary, name, onChangeValue, defaultValue, className }) => {
    return (
        <FormControl fullWidth className={className} >
            <InputLabel id="select-label">{label}</InputLabel>
            <Select
                variant="outlined"
                labelId="select-label"
                id={name}
                value={value ? value : ""}
                label={label}
                name={name}
                onChange={onChangeValue}
            >
                <MenuItem value={defaultValue? defaultValue:""} disabled={defaultValue? false: true}  >SELECCIONAR</MenuItem>
                {elements.map((val, index) =>
                    <MenuItem key={index} value={val.value.toUpperCase()}>{val.label.toUpperCase()}</MenuItem>
                )}
                {secondary?.map((val, index) =>
                    <MenuItem key={index} value={val}>{val}</MenuItem>
                )}

            </Select>
        </FormControl>
    )
};


interface MUITableProps {
    rows: MUITableRow[];
    columnLabels: MUITableColumn[];
    simple?: boolean;
};

export interface MUITableColumn {
    label: string | React.ReactElement;
    align?: "right" | "left" | "center" | "justify" | "inherit" | undefined;
}

export interface MUITableRow {
    columns: MUITableColumn[];
    link: string;
    border?: boolean
};

export const MUITable: React.FC<MUITableProps> = ({ rows, columnLabels, simple }) => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#1976d2",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));



    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {columnLabels.map((column, index) =>
                            <StyledTableCell key={index}><b>{column.label}</b></StyledTableCell>
                        )}{!simple && (
                            <StyledTableCell><b>VER</b></StyledTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => {
                        const StyledTableRow = styled(TableRow)(({ theme }) => ({
                            //rgb(144 159 245 / 63%)
                            '&:nth-of-type(odd)': {
                                backgroundColor: row.border? theme.palette.action.hover : theme.palette.action.hover,
                            },
                            // hide last border
                            '&:last-child td, &:last-child th': {
                                border: 0,
                            },
                        }));
                        return (
                            <StyledTableRow key={row.link} >
                                {row.columns.map((column, index) =>
                                    <StyledTableCell key={index}  >{column.label}</StyledTableCell>
                                )}
                                {!simple && (
                                    <StyledTableCell><Link className="" to={row.link}><Search /></Link></StyledTableCell>
                                )}
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )


};