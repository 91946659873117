import { useState, useEffect } from "react";
import { MagCardElegidos } from "../../components/cards";
import { getDetallesVotosPublic, getMagPublic, getOpcionesMagistrdosPublic } from "../../services/juntanominadora";
import * as React from "react";

const ProyeccionElegidos: React.FC = () => {
    const [show, setshow] = useState(false);
    const [candidatosElectos, setCandidatos] = useState<any[]>([]);
    const [postulantes, setpostulantes] = useState<any[]>([]);
    const [upd, setupd] = useState(false);
    const [limite, setlimite] = useState<Number>(86);
    
    const getCards = () => {
        const content = [];
        let  elements:any[] = [];
        const fontSize = Math.max(Math.min(2, ((0.85 * 15) / (candidatosElectos.length + 3))), 0.95) + "vw";
        let maxWidth:string = 20 + "vw";
        if(candidatosElectos.length > 5){
            maxWidth = 15 + "vw";
        }
        if(candidatosElectos.length > 10){
            maxWidth = 7 + "vw";
        }
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 15; j++) {
                const idx: number = j + (15 * i)
                const posttemp = postulantes.filter((val) => { return val.id !== "1" })
                const cand: any = posttemp[idx];
                const selected = candidatosElectos.filter(v => { return v.candidato_id === cand.id }).length === 1;
                if (cand && selected) {
                    elements.push(
                        <MagCardElegidos key={cand.id} position={idx + 1} maxWidth={maxWidth} mark={"✓"} name={cand.nombre} disabled={false} selected={selected} setselected={() => {} } fontSize={fontSize}/>
                    );
                };
                if(elements.length === 5){
                    let mainContainer = React.createElement("div", { className: "row my-2", key: "row" + idx}, elements);
                    content.push(mainContainer);
                    elements = [];
                }
            };

        };
        if(elements.length !== 0){
            let mainContainer = React.createElement("div", { className: "row my-2", key: "row-end"}, elements);
            content.push(mainContainer);
            elements = [];
        }
        content.push(elements);        
        return content;
    };

    useEffect(() => {
        if (!upd) {
            setupd(true);
            getMagPublic().then(v => {
                if (v.data.successed) {
                    const mag: any[] = v.data.candidatos;
                    setpostulantes(mag)
                };
            });
        }
        const inv = setInterval(() => {
            setshow(false);

            getOpcionesMagistrdosPublic().then((v) => {
                if (v.data.successed) {
                    const opciones = v.data.content;
                    const limite = opciones.find((o:any) => o.name === "limite");
                    setlimite(limite ? parseInt(limite.value) : 86);
                };
            });


            getDetallesVotosPublic().then((v) => {
                if (v.data.successed) {
                    const votes = v.data.votos;
                    const candidatos: any[] = votes.filter((v: any) => { return (v.votos >= limite && v.candidato_id !== "1") });
                    setCandidatos(candidatos);
                };
            });
        }, 2000);
        return () => clearInterval(inv);
    }, [show, upd, limite]);

    if (show) {
        return (
            <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                <h5><b>Esperando Resultados...</b></h5>
            </div>
        )
    };
    return (
        <div className="container-fluid py-3 text-center">
            <div className="d-flex align-items-center justify-content-between">
                <img className="mx-3 aling-right" src="images/escudo.png" alt="" height={65} />
                <h1 className="py-2 w-100 position-absolute start-0"><b>MAGISTRADOS ELECTOS</b></h1>
                <h4 className="w-100 position-absolute start-0" style={{top: "4.1rem", fontSize: "1.3rem"}}><b>ELECTOS {candidatosElectos.length}/15</b></h4>
                <img className="mx-3 aling-right" src="images/logo.png" alt="" height={65} />
            </div>
            <div className="d-flex align-items-center flex-column  justify-content-center">
                {getCards()}
            </div>
        </div>
    )
};

export default ProyeccionElegidos;
