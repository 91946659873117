import { Chart } from "react-google-charts";

export const BarChart: React.FC<{ list: any[] }> = ({ list }) => {
    return (
        <div style={{display:"block"}}>
            <Chart
                chartType="ColumnChart"
                width="100%"
                height={"400px"}
                data={list}
                options={{
                    chart: {
                        title: "Candidatos Seleccionados",
                        subtitle: "",
                    },
                    chartArea: { width: '73%', height: '20px', top: "5%" },
                    fontSize: 10,
                    legend:"none",
                }}
            />
        </div>
    );
}
