import React, { Fragment } from "react"
import { AccessTime } from "@mui/icons-material";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { query } from "../services/api";
import stylesVotaciones from "../styles/votacionStyles.module.css"
import '../styles/diagrama.css';
import { updateCord, updateCordComplete, updateCordDiagrama } from "../services/juntanominadora";

interface MagProps{
    selected?: boolean
    disabled?: boolean
    name: string
    position: number
    setselected: () => void
    mark: string
    fontSize?: string
    maxWidth?: string
    top?: string
    left?: string
    width?: string
    height?: string
}
export const MagCard: React.FC<MagProps> = ({selected, disabled, name, position, setselected, mark, fontSize}) => {
    return (
        <div className={`card px-0 mx-1 col ${stylesVotaciones.cardBorder} ${selected ? stylesVotaciones.candidatoSelected : ""} ${disabled ? stylesVotaciones.candidatoDisabled : ""}`} onClick={setselected} style={fontSize ? {fontSize: fontSize, lineHeight: fontSize} : {}}>
                <img alt={name} src={name !== "Cargando..." ? `images/candidatos/${name}.jpg` : require("../imgs/notfound.png")} width={"100%"} />
            <div className={`${stylesVotaciones.cardBody} ${stylesVotaciones.cardLine}  card-body`}>
                <small className="card-title text-center w-100">{name}</small>
            </div>
            <div className={`${stylesVotaciones.cardBody} card-body`}>
                <span className={`${stylesVotaciones.cardNumber} ${selected ? "d-none" : ""}`}>{position < 10 ? '0' + position : position}</span>
                <span className={`${stylesVotaciones.cardX} ${selected ? "" : "d-none"}`}>{mark}</span>
            </div>
        </div>
    )
}

export const MagCardElegidos: React.FC<MagProps> = ({selected, disabled, name, position, setselected, mark, fontSize, maxWidth}) => {
    return (
        <div className={`card px-0 mx-1 col ${stylesVotaciones.cardBorder} ${stylesVotaciones.candidatoSelected}`} style={{fontSize: fontSize, lineHeight: fontSize, maxWidth: maxWidth}}>
                <img alt={name} src={`images/candidatos/${name}.jpg`} width={"100%"} />
            <div className={`${stylesVotaciones.cardBody} ${stylesVotaciones.cardLine} ${stylesVotaciones.cardTitle} card-body`}>
                <small className="card-title text-center w-100">{name}</small>
            </div>
            <div className={`${stylesVotaciones.cardBody} card-body`}>
                <span className={`${stylesVotaciones.cardX}`}>{mark}</span>
            </div>
        </div>
    )
}

interface MagPropsBG{
    name: string,
    fontSize: string,
    top: string,
    left: string,
    width: string,
    height: string,
}

export const MagCardElegidosBG: React.FC<MagPropsBG> = ({name, top, left, width, height, fontSize}) => {
    return (
        <div className={`position-absolute`} style={{top: top, left: left, width: width, height: height }}>
            <img alt={name} src={`images/candidatos/${name}.jpg`} width={"100%"} style={{boxShadow: "0.1vw 0.3vh 0.5vw #303030", height:"18vh"}} />
            <div className={``}>
                <small className="d-block text-center w-100" style={{fontSize: fontSize,  fontWeight: "bold", marginTop: "1vh"}}>{name}</small>
            </div>
        </div>
    )
}

export const MagCardVotos: React.FC<MagProps> = ({selected, disabled, name, position, setselected, mark, fontSize}) => {
    return (
        <div className={`card px-0 mx-1 col ${stylesVotaciones.cardBorder} ${selected ? stylesVotaciones.candidatoSelected : ""} ${disabled ? stylesVotaciones.candidatoDisabled : ""}`} onClick={setselected} style={fontSize ? {fontSize: fontSize, lineHeight: fontSize} : {}}>
                <img alt={name} src={name !== "Cargando..." ? `images/candidatos/${name}.jpg` : require("../imgs/notfound.png")} width={"100%"} />
            <div className={`${stylesVotaciones.cardBody} ${stylesVotaciones.cardLine}  card-body`}>
                <small className="card-title text-center w-100">{name}</small>
            </div>
            <div className={`${stylesVotaciones.cardBody} card-body`}>
                <span className={`${stylesVotaciones.cardNumber}`} style={{fontSize: "2.9vw"}}>{position < 10 ? '0' + position : position}</span>
            </div>
        </div>
    )
}

type MediaCardProps = {
    title: string,
    description: string,
    date: string,
    id: string,
    image?: string
    type?: 1 | 2
};

export function MediaCard(props: MediaCardProps) {
    return (
        <Card className="card" sx={{ marginBottom: 2, cursor: "pointer" }} data-aos="fade-up" data-aos-delay={50}>
            {props.type === 2 ? (
                <iframe title={Math.floor(Math.random() * 100000).toString()} src={props.image} height="200" width="100%" allowFullScreen />
            ) : (
                <CardMedia
                    component="img"
                    height="200"
                    image={props.image ? `${query}${props.image}` : "/notfound.png"}
                    alt="image"
                />
            )}
            <CardContent sx={{ height: 160, overflow: "hidden" }}>
                <Typography gutterBottom variant="h5" component="div" sx={{ height: 44, overflow: "hidden", fontSize: 18 }}>
                    {props.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ width: "100%", height: 60, overflow: "hidden" }}>
                    {props.description}
                </Typography>
                <small className='mt-3' style={{ color: "GrayText" }} ><AccessTime /> {props.date}</small>

            </CardContent>
            <Link className="stretched-link" to={`/publicaciones/${props.id}`} />
        </Card>
    );
};

interface IcmCard {
    id: string;
    nombre: string;
    bancada?: string;
    departamento: string;
    imagen: string;
    cargo: string;
    titulo?: string;
    descripcion?: string;
    disabled? : boolean;
    formula: string;
    email: string;
    onClickElement: (id:string) => void;
}

export const CmCard: React.FC<IcmCard> = ({ id, nombre,email, bancada, departamento, cargo, titulo, descripcion, imagen, disabled, formula, onClickElement }) => {
    const [img, setImg] = React.useState<string | any>("");
    const [upd, setUpd] = React.useState(false);

    function fnClick(){
        if(!disabled){
            const data = {
                id: id,
                nombre: nombre,
                bancada: bancada,
                departamento: departamento,
                cargo: cargo,
                titulo: titulo,
                imagen: imagen,
                formula: formula,
                email: email
            };
            localStorage.setItem("congresista", JSON.stringify(data));
            onClickElement(id);
        }else{
            alert("El congresista ya ha votado, no es posible seleccionarlo")
        }
    };

    React.useEffect(() => {
        if (!upd) {
            /*
            try {
                const imgtemp = require(`../imgs${imagen}`);
                setImg(imgtemp);
            } catch (error) {
                const imgtemp = require(`../imgs/notfound.png`);
                setImg(imgtemp);
            }
            */
            setImg(`images${imagen}`);
            setUpd(true);
        };
    }, [upd, imagen, img]);

    return (
        <div style={!disabled ? {cursor:"pointer"}:{ opacity: 0.25}} className={`col-lg-2 col-md-4 col-sm-12 d-flex align-items-stretch mb-3`} data-aos="fade-up" data-aos-delay="100"  onClick={fnClick}>
            <div className="member rounded shadow-sm bg-light" style={{width:"100%"}}>
                <div className="member-img">
                    <CardMedia
                        className="rounded"
                        component="img"
                        style={{}}
                        image={img}
                        alt={nombre}
                    />
                </div>
                <div className="member-info px-2 py-2">
                    <h6 className="py-1" style={{ height: 40, overflowY: 'hidden' }}><b>{nombre}</b></h6>
                    <div style={{fontSize:14}}>
                        <small >{departamento}</small>, <small>{bancada}</small>
                    </div>
                </div>
            </div>
        </div>
    )

};

interface IDE {
    top: number,
    left: number,
    id: number,
    voted: boolean,
};

const w = window.innerWidth * 0.015
const h = window.innerWidth * 0.015
const r = "50%"
const color:string = "#999999";
const colorVerde:string = "#00ff00";

export const VotacionElement: React.FC<IDE> = ({ top, left, id, voted}) => {

    function dragElement(e: any) {
        const pX = e.pageX - e.target.offsetWidth / 2;
        const pY = e.pageY - e.target.offsetHeight / 2;

        e.target.style.left = pX + "px"
        e.target.style.top = pY + "px"
        
        const xF = pX / window.innerWidth;
        const yF = pY / window.innerHeight;

        updateCord({
            x: xF,
            y: yF,
            seat: id
        })
    };
    return (
        <div className="d-block shadow" draggable onDragEnd={dragElement}
        style={{
            width: w, borderRadius: r, position: "fixed",
            top: (window.innerHeight * top),
            left: (window.innerWidth * left),
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: voted ? colorVerde : color,
            cursor: "crosshair"
        }}>

            
            <div className="rounded justify-content-center" style={{ height: h, display: "flex", alignItems: "center", }} >
                <div className="text-center" style={{ fontSize: 10, fontStretch: "condensed", paddingLeft: 0, paddingRight: 0, color: "#ffffff"}}>
                    <b></b>
                </div>
            </div>
        </div>
    )
};


let sizePercent = 0.038;
const rc = "25%"
const colorc:string = "#242424";
const colorVerdec:string = "rgb(0, 255, 0, 0.8)";
const colorRojo:string = "rgb(249, 0, 0, 0.8)";
const colorAmarillo:string = "rgb(249, 253, 0, 0.8)";
const colorNegro:string = "#000000";
const colorGris:string = '#cbc9c6';
const colorBlanco:string = '#ffffff';
const dragImg = new Image(0,0);
dragImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

interface VECType {
    top: number,
    left: number,
    id: number,
    congresistaName: any | undefined,
    votedCSJ?: boolean,
    fonSizePercent: number,
    groupColor1: string,
    groupColor2: string,
    voto?: any
    backgroundTransparente?: string,
    colorFontTransparente?: string,
    nombreCompleto?: string,
    showUltimaVotacion?: boolean,
    expand?: boolean,
    updateCoord?: boolean,
};

export const VotacionElementCompleto: React.FC<VECType> = ({ top, left, id, congresistaName, votedCSJ, fonSizePercent, groupColor1, groupColor2, voto, backgroundTransparente, colorFontTransparente, nombreCompleto, showUltimaVotacion, expand, updateCoord}) => {

    function onDragStart(e: any){
        e.dataTransfer.setDragImage(dragImg, 0, 0);
    }

    function onDragCapture(e: any){

        const pX = e.pageX - e.target.offsetWidth / 2;
        const pY = e.pageY - e.target.offsetHeight / 2;
        if(pX > 1){
            e.target.style.left = pX + "px"
            e.target.style.top = pY + "px"
        }
    }

    function onDragEnd(e: any) {
        
        let pX = e.pageX;
        let pY = e.pageY;

        if(!expand) {
            pX = e.pageX - e.target.offsetWidth / 2;
            pY = e.pageY - e.target.offsetHeight / 2;
            
            e.target.style.left = pX + "px"
            e.target.style.top = pY + "px"
        } else {
            e.target.style.left = pX - (e.target.offsetWidth / 2) + "px"
            e.target.style.top = pY - (e.target.offsetHeight / 2) + "px"
        }


        const xF = pX / window.innerWidth;
        const yF = pY / window.innerHeight;
        
        if(updateCoord){
            if(!expand) {
                updateCordComplete({
                    x: xF,
                    y: yF,
                    seat: id
                })
                
            } else {                
                updateCordDiagrama({
                    x: xF,
                    y: yF,
                    seat: id
                })
            }
        }
    }

    let colorBackground = backgroundTransparente ? backgroundTransparente : colorc;
    let colorFont = colorFontTransparente ? colorFontTransparente : colorGris;

    if(showUltimaVotacion && votedCSJ) {
        colorBackground = colorVerdec;
        colorFont = colorNegro;
    }

    if(showUltimaVotacion && voto && voto.length > 0) {
        if(voto[0].Vote1 === 1){
            colorBackground = colorVerdec;
            colorFont = colorNegro;
        } else if (voto[0].Vote3 === 1) {
            colorBackground = colorRojo;
            colorFont = colorBlanco;
        } else if (voto[0].Vote2 === 1) {
            colorBackground = colorAmarillo;
            colorFont = colorNegro;
        }
    }

    let heightCard = window.innerHeight * sizePercent * 1.5;

    if(expand){
        sizePercent = 0.048;
        heightCard = window.innerHeight * sizePercent * 2;
    }
    const widthCard = window.innerWidth * sizePercent;
    const fontSize = window.innerWidth * fonSizePercent;

    if(expand){
        //colorBackground = colorc;
        //colorFont = colorNegro;

        top = (window.innerHeight * top) - (heightCard  / 2);
        left = (window.innerWidth * left) - (widthCard / 2);
    } else {
        top = (window.innerHeight * top);
        left = (window.innerWidth * left);
    }

    return (
        <div className={`d-block shadow cardDiagrama`} draggable={updateCoord ? true : false} onDragStart={updateCoord ? onDragStart : undefined} onDragCapture={updateCoord ? onDragCapture : undefined} onDragEnd={updateCoord ? onDragEnd : undefined}
        style={{
            overflow: "hidden",
            borderRadius: rc,
            top: top,
            left: left,
            width: widthCard,
            height: heightCard,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: colorBackground,
            borderLeftColor: groupColor1, borderTopColor: groupColor1, borderRightColor: groupColor2, borderBottomColor: groupColor2
        }}
        >
            <div className="text-center" style={{
                width: "100%",
                height: "100%", 
                display: "flex",
                alignItems: "center",
                color: colorFont,
            }}>
                {expand && 
                    <>
                    { congresistaName && <img draggable={false}  alt={congresistaName} src={`images/congresistas/${nombreCompleto}.jpg`} width="100%" height="100%" /> }
                    <b className="congresistaName" style={{ 
                        fontSize: fontSize +"px",
                        lineHeight: fontSize +"px",
                        background: colorBackground,
                        color: colorFont,
                    }}>{congresistaName}</b>
                    </>
                }
                {!expand && 
                    <b style={{ 
                        fontSize: fontSize +"px",
                        lineHeight: fontSize +"px",
                        color: colorFont,
                    }}>{congresistaName}</b>

                }
            </div>
        </div>
    )
};

