import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import cn from "../imgs/logo.png";
import { Home, PowerSettingsNew } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ModulePermission } from '../helpers/config';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export interface DrawnerElement {
    label: string,
    view: React.ReactNode,
    icon?: any,
    title: string | React.ReactNode,
    url?: string
}

export interface DrawnerLink {
    label: string,
    to: string
    icon?: any,
}

interface PersistentProps {
    elements?: DrawnerElement[];
    links?: DrawnerLink[];
    selected: DrawnerElement;
    hideHomeButton?: boolean;
    defaultClosed?: boolean
    setselected: (element: DrawnerElement) => void
};

const PersistentDrawer: React.FC<PersistentProps> = ({ elements, links, selected,hideHomeButton, defaultClosed,  setselected }) => {

    const navigate = useNavigate();

    const theme = useTheme();
    const [open, setOpen] = React.useState(!defaultClosed);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [upd, setupd] = useState(false);
    const [mPath, setmPath] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!upd) {
            setupd(true);
            const l = localStorage.getItem("us");
            const user = l ? JSON.parse(l) : undefined;
            const mainpath = user ? ModulePermission.find(v => { return v.name === "Principal" && v.rols.find(t => { return t === user.rol }) }) : undefined;
            setmPath(mainpath);
        }
    }, [upd]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {selected?.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <img src={cn} alt="cn" width={drawerWidth - 140} style={{ marginTop: 5, marginRight: 5, marginLeft: 5, marginBottom: 5 }} />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {elements && elements.map((element) => (
                        <ListItem key={element.label} disablePadding >
                            <ListItemButton onClick={() => { element.url ? window.open( element.url, '_blank') : setselected(element)}} selected={element.label === selected?.label}>
                                <ListItemIcon>
                                    {element.icon}
                                </ListItemIcon>
                                <ListItemText primary={element.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {links && links.map((element) => (
                        <ListItem key={element.label} disablePadding>
                            <ListItemButton onClick={() => { navigate(element.to) }} >
                                <ListItemIcon>
                                    {element.icon}
                                </ListItemIcon>
                                <ListItemText primary={element.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <Divider />

                    {(mPath && !hideHomeButton ) && (
                        <div>
                            <ListItem disablePadding >
                                <ListItemButton onClick={() => { navigate("/") }} >
                                    <ListItemIcon>
                                        <Home />
                                    </ListItemIcon>
                                    <ListItemText primary={"Panel Principal"} />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                        </div>
                    )}
                    
                    <ListItem disablePadding >
                        <ListItemButton onClick={() => { localStorage.removeItem("tk"); localStorage.removeItem("us"); window.location.reload() }} >
                            <ListItemIcon>
                                <PowerSettingsNew />
                            </ListItemIcon>
                            <ListItemText primary={"Salir"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {selected?.view}
            </Main>
        </Box>
    );
};

export default PersistentDrawer;