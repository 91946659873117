import { Button } from "@mui/material";
import React, {useState, useEffect} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../components/container";
import DraftEditor from "../../components/DraftEditor";
import { deletePost, getPostById } from "../../services/api";

const PDetails: React.FC = () => {
    const [upd, setupd] = useState(false);
    const [post, setpost] = useState<any>({});
    const [initialContent, setInitialContent] = useState<any>(undefined);
    const [updating, setUpdating] = useState(true);

    const {id}: any = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if(!upd && id){
            setupd(true);
            getPostById(id).then(v=>{
                if(v.data.successed){
                    setpost(v.data.post);
                    setInitialContent(v.data.post.body)
                };
            });
        };
        if (post.id !== id) {
            setupd(false);
            setUpdating(true);
        };
    },[upd, id]);

    function delPost(){
        deletePost(id).then(v=>{
            if(v.data.successed){
                navigate("/")
            };
        });
    };

    return(
        <MainContainer name="Comunicaciones" showHead title={
            <div className="mx-0">
                <Button variant="contained" className="mx-2" color="success"><Link className="stretched-link" to={`/editar/publicaciones/${id}`} />Editar</Button>
                <Button variant="contained" className="mx-2" color="error" onClick={delPost} >Eliminar</Button>
            </div>
        }>
            <div className="container mt-3">
                {initialContent && (
                    <DraftEditor disabled initialContent={initialContent} exportDataRaw={() => { }} onSavePost={()=>{}} />
                )}
            </div>
        </MainContainer>
    )
};

export default PDetails;