import { useState, useEffect } from "react";
import { VotacionElementCompleto } from "../../components/cards";
import { getCongresistasPublic, getGruposBancadaPublic, getVotosVotacionActivaPublic, getSeatCoordinateCompletePublic, getLogDelegatesSeatNumberActiveConferencePublic } from "../../services/juntanominadora";
import * as React from "react";
import stylesVotaciones from "../../styles/votacionStyles.module.css"
import { useLocation } from 'react-router-dom'

const DiagramaCompletoVotosSistemaCSJ: React.FC = () => {
    const [congresistas, setCongresistas] = useState<any[]>([]);
    const [gruposBancada, setGruposBancada] = useState<any[]>([]);
    const [votosSistemaCSJ, setvotosSistemaCSJ] = useState<any[]>([]);
    const [coordenadas, setCoordenadas] = useState<any[]>([]);
    const [logDelegatesSeatNumber, setLogDelegatesSeatNumber] = useState<any[]>([]);
    const isImpresion:boolean = useLocation().pathname === "/diagramacompletovotossistemacsjimpresion";

    const [colorVotosRecibidos, setcoordecolorVotosRecibidosnadas] = useState<string>("#ffffff");
    const [backgroundTransparente, setbackgroundTransparente] = useState<string>();
    const [colorFontTransparente, setcolorFontTransparente] = useState<string>();


    useEffect(() => {

        if(isImpresion && !backgroundTransparente){
            setcoordecolorVotosRecibidosnadas("#000000");
            setbackgroundTransparente("#ffffff");
            setcolorFontTransparente("#000000");
        }

        // Obtener todos los congresistas
        if(congresistas.length === 0){
            getCongresistasPublic().then((v) => {
                if (v.data.successed) {
                    setCongresistas(v.data.content);
                };
            });
        }
        
        if(gruposBancada.length === 0){
            getGruposBancadaPublic().then((v) => {
                if (v.data.successed) {
                    setGruposBancada(v.data.content);
                };
            });
        }

        const inv = setInterval(() => {
            // Obtener LogDelegate With Seat Number de Conferencia Activa
            getLogDelegatesSeatNumberActiveConferencePublic().then((v) => {
                if (v.data.successed) {
                    setLogDelegatesSeatNumber(v.data.content);
                };
            });

            // Obtener Votos Votacion Activa Magistrados Sistema CSJ
            getVotosVotacionActivaPublic().then((v) => {
                if (v.data.successed) {
                    const votes = v.data.content;
                    const votosSistemaCSJ = Array.from(new Map(votes.map( (item:any) => { return [item.user_id, item]})), function (item) { return item[1] });
                    setvotosSistemaCSJ(votosSistemaCSJ);
                };
            });

            // Obtener Coordenadas Complete
            getSeatCoordinateCompletePublic().then((v) => {
                if (v.data.successed) {
                    setCoordenadas(v.data.content);
                };
            });
        }, 2000);
        return () => {clearInterval(inv)}
    }, [congresistas, gruposBancada, coordenadas, votosSistemaCSJ, isImpresion, backgroundTransparente]);

    return (
        <div className={`container-fluid ${isImpresion ? stylesVotaciones.diagramaCompletoBackgroundTransparente : stylesVotaciones.diagramaCompletoBackground}`} style={{ 
            }}>
            {coordenadas.map((coor: any, index) => {
                let voteDelegate = votosSistemaCSJ.filter((voto:any) => { return voto.seatnumber === coor.SeatNumber }); 
                let congresista = voteDelegate.length ? congresistas.filter((c:any) => { return `${c.delegate_id}` === voteDelegate[0].user_id}) : [];

                // Si no hay voto con este asiento, buscar tarjeta con este asiento, mostrar nombre y mostrar si votó o no
                if(voteDelegate.length === 0){
                    const logDelegateSeatNumber = logDelegatesSeatNumber.filter((ld:any) => { return ld.SeatNumber === coor.SeatNumber });
                    if(logDelegateSeatNumber.length){
                        congresista = congresistas.filter((c:any) => { return c.delegate_id === logDelegateSeatNumber[0].delegate_id});
                        voteDelegate = votosSistemaCSJ.filter((voto:any) => { return voto.user_id === `${logDelegateSeatNumber[0].delegate_id}`});
                    }
                }

                const votedCSJ = voteDelegate.length ? true : false;

                const longerName = congresista.length ? Math.max(congresista[0].FirstName.split(" ")[0].length, congresista[0].LastName.split(" ")[0].length) : 0;
                let caracteresExtras = longerName - 8;
                if(longerName === 8){
                    caracteresExtras = 1;
                }
                const fonSizePercent = caracteresExtras > 0 ? 0.00625 - (caracteresExtras * 0.00052) : 0.00625;

                const congresistaName = congresista.length ? `${congresista[0].FirstName.split(" ")[0]} ${congresista[0].LastName.split(" ")[0]}`.toUpperCase() : ""; //coor.SeatNumber;

                const bancada = congresista.length ? gruposBancada.filter((b:any) => { return b.group_name === congresista[0].bancada.toUpperCase().trim() }) : [];

                const groupColor1 = bancada.length ?  bancada[0].group_color1 : '#9a9a9a';
                const groupColor2 = bancada.length ?  bancada[0].group_color2 : '#9a9a9a';
                
                return (
                    <div key={coor.SeatNumber}>
                        <VotacionElementCompleto backgroundTransparente={backgroundTransparente} colorFontTransparente={colorFontTransparente} top={coor.YCoordinate} left={coor.XCoordinate} id={coor.SeatNumber} congresistaName={congresistaName} votedCSJ={votedCSJ}  fonSizePercent={fonSizePercent} groupColor1={groupColor1} groupColor2={groupColor2}/>
                    </div>
                )
            })}
            <div style={{ position: "absolute", top: "4vh", right:"3vw", color: colorVotosRecibidos, fontSize: "1.2vw"}}><span>VOTOS RECIBIDOS: {votosSistemaCSJ.length}</span></div> 
        </div>
    )
};
export default DiagramaCompletoVotosSistemaCSJ;
