import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModulePermission, PathDefault } from "../helpers/config";
import { VerifyToken } from "../services/api";

interface ContainerProps {
    showHead?: boolean;
    title?: React.ReactNode;
    children: React.ReactNode;
    name: string;
};

interface IUser {
    _id: string;
    firstName: string;
    lastName: string;
    userName: string;
    rol: string;
};

const MainContainer: React.FC<ContainerProps> = ({ showHead, title, children, name }) => {

    const [upd, setupd] = useState(false);
    const [updating, setupdating] = useState(true);
    const [user, setUser] = useState<IUser | undefined>(undefined);
    const [rols, setRols] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!upd) {
            setupd(true);
            setupdating(true);
            const tk = localStorage.getItem("tk");
            if (name) {
                const item = ModulePermission.find(v => { return v.name === name });
                setRols(item ? item.rols : []);
            };
            if (tk) {
                VerifyToken(tk).then(v => {
                    if (!v.data.successed) {
                        navigate("/login")
                    } else {
                        setUser(v.data.user);
                        localStorage.setItem("us", JSON.stringify(v.data.user));
                    };
                    setupdating(false);
                });
            } else {
                navigate("/login");
            };
        };
    }, [upd, navigate, name]);

    if (updating) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div>
                    <CircularProgress />
                </div>
            </div>
        )
    };
    
    function getPathDefault() {
        const t = PathDefault.find(v => { return v.rol === user!.rol })
        return t ? t.path : "/"
    };

    if (!(user && rols.filter((v) => v === user.rol).length > 0)) {
        navigate(getPathDefault()!);
        return(
            <div></div>
        )
    };

    return (
        <div className="container-fluid">
            <div className="row shadow-sm rounded py-3 px-3" style={showHead ? {} : { display: "none" }}>
                <div className="col-lg-2">
                    <Button onClick={() => {
                        navigate(getPathDefault()!);
                    }} variant="contained">Panel Principal</Button>
                </div>
                <div className="col-lg-8">
                    {title}
                </div>
            </div>
            <div className="mt-0">
                {children}
            </div>
        </div>
    )

};

export default MainContainer;