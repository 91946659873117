import { Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import MainContainer from "../../components/container";
import Editor from "../../views/edit";

export const PostEditor: React.FC = () => {
    const {id}: any = useParams();
    return (
        <MainContainer name="Comunicaciones" showHead title={
            <div className="mx-0">
                <Button variant="contained" className="mx-2" color="success"><Link className="stretched-link" to={`/publicaciones/${id}`} />Volver</Button>
            </div>
        }>
            <div className="container alert alert-warning my-3">Debe guardar los cambios realizados antes de salir o los mismos no se verán reflejados.</div>
            <Editor onSavedPost={()=>{}} />
        </MainContainer>
    )
};

export default PostEditor;