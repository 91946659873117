import { Home, RestartAlt, SwitchAccount } from "@mui/icons-material";
import { Avatar, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainContainer from "../../components/container";
import PersistentDrawer, { DrawnerElement } from "../../components/persistenDrawer";
import { VerificarActiva } from "../../services/juntanominadora";
import Votacion from "../../views/juntanominadora/votacion";
import Votantes from "../../views/juntanominadora/votantes";

const Magistrados: React.FC = () => {
    return (
        <MainContainer name="Magistrados">
            <DashVotantes />
        </MainContainer>
    )
};

const DashVotantes: React.FC = () => {
    const [user, setuser] = useState<any | undefined>(undefined);
    const [upd, setupd] = useState(false);
    const [show, setshow] = useState(true);
    const [updating, setupdating] = useState(true);

    const elements: DrawnerElement[] = [
        { title: `SELECCIÓN DE CONGRESISTA`, label: "Votantes", view: <Votantes onClickElement={onSavedPost} />, icon: <SwitchAccount /> },
        { title: user ? `Bienvenido H.C. ${user.nombre}` : ``, label: "Inicio", view: <Votacion onSave={() => { onSavedPost(0) }} />, icon: <Home /> },
    ];
    const [selected, setselected] = React.useState<DrawnerElement>(elements[0]);

    useEffect(() => {
        if (!upd) {
            const us = localStorage.getItem("congresista");
            setupd(true);
            if (us) {
                const t = JSON.parse(us);
                setuser(t);
                const cntemp = { id: t.id, nombres: t.nombre, apellidos: "", img: t.imagen, formula: t.formula, cargo: t.cargo, departamento: t.departamento };
            
                setselected(
                    { title: t ? 
                    <div className="d-flex">
                        <Avatar alt={t.nombre} src={`images${t.imagen}`} />
                        <div className="px-3 d-flex align-items-center" >{`H.D. ${t.nombre}`} </div>
                    </div>
                    : ``, label: "Inicio", view: <Votacion onSave={() => { onSavedPost(0) }} user={cntemp}/>, icon: <Home /> }
                );
            };
        };
        const int = setInterval(()=>{
            VerificarActiva().then(v=>{
                if(v.data.successed){
                    const ac = localStorage.getItem("votacion");
                    if(ac && ac !== v.data.votacion.nombre){
                        window.location.reload();
                    }
                    localStorage.setItem("votacion", v.data.votacion.nombre);
                    setshow(false);
                }else{
                    setshow(true);
                }
                setupdating(false);
            });
        }, 3000);
        return () => clearInterval(int);
    }, [user, upd, selected, show]);

    function onSavedPost(idx: number = 1) {
        setselected(elements[idx]);
        setupd(false);
    };

    if(updating){
        return(
            <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
                <CircularProgress />
            </div>
        )
    }

    if(show){
        return(
            <div className="container-fluid d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
                <div className="text-center">
                    <h6>No hay una votacion activa</h6>
                    <Button size="large" onClick={()=> setupd(false)}><RestartAlt /></Button>
                </div>
            </div>
        )
    };

    return (
        <PersistentDrawer defaultClosed hideHomeButton selected={selected} setselected={setselected} elements={elements}></PersistentDrawer>
    )
}

export default Magistrados;
