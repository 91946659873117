import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainContainer from "../../components/container";

const UsersDetails: React.FC = () => {
    const [upd, setupd] = useState(false);
    const [user, setuser] = useState<any>({});
    const {id}: any = useParams();

    useEffect(()=> {
        
    },[upd, user, id]);

    return(
        <MainContainer showHead name="Usuarios">

        </MainContainer>
    )
};

export default UsersDetails;