import { Article, Edit } from "@mui/icons-material";
import React from "react";
import MainContainer from "../../components/container";
import PersistentDrawer, { DrawnerElement } from "../../components/persistenDrawer";

import Editor from "../../views/edit";
import Publicaciones from "../../views/posts";

const Comunicaciones: React.FC = () => {
    return (
        <MainContainer name="Comunicaciones">
            <Temp />
        </MainContainer>
    )
};

const Temp: React.FC = () => {
    const elements: DrawnerElement[] = [
        { title: "Editor de Publicaciones", label: "Editor", view: <Editor onSavedPost={onSavedPost} />, icon: <Edit />},
        { title: "Publicaciones", label: "Publicaciones", view: <Publicaciones />, icon: <Article /> }
    ];
    const [selected, setselected] = React.useState<DrawnerElement>(elements[0]);

    function onSavedPost(){
        setselected(elements[1]);
    };
    return(
        <PersistentDrawer selected={selected} setselected={setselected} elements={elements}></PersistentDrawer>
    )
}

export default Comunicaciones