import { Button, TextField } from "@mui/material";
import moment from "moment";
import React, {useState, useEffect} from "react";
import { FormSelect, MUITable } from "../components/MUIComponents";
import useFormHelper from "../helpers/useFormHelper";
import { getApiMeetings, newMeeting } from "../services/api";

const AddMeeting: React.FC = () => {
    const states = useState({
        topic: "",
        lounge: "",
        link: ""
    });

    const {
        values,
        handleChange
    } = useFormHelper(states);

    function onSave(){
        newMeeting(values).then(v=>{
            if(v.data.successed){
                window.location.reload();
            };
        });
    };

    return (
        <div className="px-lg-5 px-sm-0 px-md-2">
            <TextField className="my-2" fullWidth id="standard-basic" label="Tema" variant="standard" name="topic" onChange={handleChange} />
            <TextField className="my-2" fullWidth id="standard-basic" label="Link" variant="standard" name="link" onChange={handleChange} />
            <FormSelect className="my-2" name="lounge" onChangeValue={handleChange} value={values.lounge} label="Salon" elements={[
                {label: "SALON RAMON ROSA", value:"SALON RAMON ROSA"},
                {label: "SALON CULTURAL", value:"SALON CULTURAL"},
                {label: "SALON VIP", value:"SALON VIP"},
                {label: "SALON RETRATOS", value:"SALON RETRATOS"},
                {label: "SALA DE REUNIONES DE LA BANCADA LIBERAL", value:"SALA DE REUNIONES DE LA BANCADA LIBERAL"},
                {label: "SALA DE REUNIONES DE SECRETARIA", value:"SALA DE REUNIONES DE SECRETARIA"},
                {label: "SALA DE CONFERENCIAS DE LEGISLATIVA", value:"SALA DE CONFERENCIAS DE LEGISLATIVA"},
                {label: "DESPACHO PRIVADO DEL PRESIDENTE", value:"DESPACHO PRIVADO DEL PRESIDENTE"},

            ]} />
            <Button className="my-3" variant="contained" onClick={onSave}>guardar</Button>
        </div>
    );
};

export const MeetingList = () => {
    const [upd, setupd] = useState(false);
    const [meetings, setmeetings] = useState<any[]>([]);
    useEffect(() => {
        if(!upd){
            setupd(true);
            getApiMeetings().then(v=>{
                if(v.data.successed){
                    const mtemp:any[] = v.data.meetings;
                    const mresp: any[] = [];
                    for(let e of mtemp){
                        const l = { columns: [  {label: e.topic.toUpperCase() }, {label: e.lounge.toUpperCase() }, {label: moment(e.date).format("DD/MM/YY  hh:mm A")}, {label:e.state? "PRESENTANDO" : "----"} ], link:`/reuniones/${e._id}` };
                        mresp.push(l);
                    }
                    setmeetings(mresp);
                };
            });
        };
    },[upd, meetings]);
    return(
        <div className="py-2">
            <MUITable columnLabels={[{label:"NOMBRE"},{label:"SALON"},{label:"FECHA"},{label:"ESTADO"}]} rows={meetings} />
        </div>
    );
};


export default AddMeeting;