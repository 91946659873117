import { AtomicBlockUtils, convertFromRaw, convertToRaw, EditorState, RichUtils } from 'draft-js';
import { useState } from 'react';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';

//import createImagePlugin from '@draft-js-plugins/image';
import createImagePlugin from '../helpers/draft-image';
import createVideoPlugin from '../helpers/draft-video';
import createTextAlignmentPlugin from '../helpers/draft-text-alignment';
import createResizeablePlugin from '../helpers/resizeable';

import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import BarState from './BarState';
import alignmentStyles from '../styles/alignmentStyles.module.css';
import EditorStyles from '../styles/Home.module.css';

import createLinkifyPlugin from '@draft-js-plugins/linkify';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';

import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/image/lib/plugin.css'
import '@draft-js-plugins/linkify/lib/plugin.css';
import { Button } from '@mui/material';

const theme: any = {
    theme: { alignmentStyles }
}

const textAlignmentPlugin = createTextAlignmentPlugin(theme);
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();

const linkifyPlugin = createLinkifyPlugin({
    component(props) {
        // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
        return <a {...props} onClick={() => { window.open(props.href) }} />;
    },
    customExtractLinks: (text) =>
        linkifyIt().tlds(tlds).set({ fuzzyEmail: false }).match(text),
});

const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator,
);

const imagePlugin = createImagePlugin({ decorator });
const videoPlugin = createVideoPlugin();
const { types } = videoPlugin;

const plugins = [
    textAlignmentPlugin,
    blockDndPlugin,
    focusPlugin,
    alignmentPlugin,
    resizeablePlugin,
    imagePlugin,
    linkifyPlugin,
    videoPlugin
];

const initial: any = {
    entityMap: {},
    blocks: [
        {
            key: '9gm3s',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        }
    ]
};

interface IDraftEditor {
    initialContent?: any,
    disabled?: boolean,
    exportDataRaw: (editor: any) => void;
    onSavePost: (e:any) => void;
};
const DraftEditor: React.FC<IDraftEditor> = ({ initialContent, disabled, exportDataRaw, onSavePost }) => {
    const [editor, seteditor] = useState<any>({});
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromRaw(initialContent ? initialContent : initial)))

    function handleInlineStyle(name: string) {
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            setEditorState(RichUtils.toggleInlineStyle(editorState, name));
        } else {
            setEditorState(EditorState.forceSelection(editorState, selection));
        };
    };

    function handleBlockStyle(command: string) {
        setEditorState(RichUtils.toggleBlockType(editorState, command));
    };

    function addImage(src: string = "https://s-media-cache-ak0.pinimg.com/originals/83/6b/66/836b662d2af15efa2631e5bd83d7229d.jpg") {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: src });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " "));
        
    };

    function addVideo(src: string = "https://www.youtube.com/watch?v=47dtFZ8CFo8&list=RDKIAovnfAWkQ&index=11") {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(types.VIDEOTYPE, "IMMUTABLE", { src: src });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " "));
    }

    function onChangeeditorState(ed: EditorState) {
        setEditorState(ed);
        console.log(convertToRaw(ed.getCurrentContent()))
        exportDataRaw(convertToRaw(ed.getCurrentContent()));
    };

    function focus() {
        editor.editor.focus();
    };

    const styleMap: any = {
        "left": {
            width: "100%",
            textAlign: "right",
        },
        "IMAGE": {
            marginRight: 100
        }
    }

    return (
        <div className='' style={{ position: "relative" }}>
            {!disabled && (
                <BarState insertVideoLink={addVideo} onSavePost={() => onSavePost(convertToRaw(editorState.getCurrentContent()))} handleInlineStyle={handleInlineStyle} handleBlockStyle={handleBlockStyle} handleImageUpload={addImage} />
            )}
            <Button className="d-none" onClick={() => addVideo("https://www.youtube.com/watch?v=KIAovnfAWkQ&list=RDMMDYed5whEf4g&index=9")}>video</Button>
            <div id="editor" className={EditorStyles.editor} onClick={focus}  >
                <Editor
                    editorState={editorState}
                    placeholder={"Contenido de la publicación..."}
                    onChange={onChangeeditorState}
                    plugins={plugins}
                    customStyleMap={styleMap}
                    readOnly={disabled}
                    ref={(e) => {
                        seteditor(e);
                    }}
                />
                {!disabled && (
                    <AlignmentTool />
                )}
            </div>
        </div>
    )
};

export default DraftEditor;