import BasicTabs, { DenseMenu, MUITable } from "../components/MUIComponents";
import { useState, useEffect } from "react"
import useFormHelper from "../helpers/useFormHelper";
import { Avatar, Button, CircularProgress, FormControl, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { Delete, FileOpen, Folder, MoreVert, Save, SupervisorAccount } from "@mui/icons-material";
import { addRol, addUser, getUserRols, getUsers } from "../services/api";

const Users: React.FC = () => {
    return (
        <div>
            <BasicTabs tabs={[
                { label: "Agregar Usuario", children: <AddUser /> },
                { label: "Lista de Usuarios", children: <ListaDeUsuarios /> },
                { label: "Tipos de Usuarios", children: <TipoDeUsuario /> },
                { label: "Asignacion de Permisos", children: <div></div> },
            ]} />
        </div>
    )
};

export const deps: string[] = [
    "ATLANTIDA", "CHOLUTECA", "COLON", "COMAYAGUA", "COPAN", "CORTES", "EL PARAISO", "FRANCISCO MORAZAN", "GRACIAS A DIOS", "INTIBUCA", "ISLAS DE LA BAHIA", "LA PAZ", "LEMPIRA", "OCOTEPEQUE", "OLANCHO", "SANTA BARBARA", "VALLE", "YORO"
]

export const AddUser: React.FC = () => {

    const [upd, setUpd] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [userType, setuserType] = useState([]);
    const [message, setMessage] = useState({ status: false, message: "" });
    const [alerts, updateAlerts] = useState({
        email: false,
        password: false,
        confirmation: false
    })

    const states = useState({
        dni: "",
        phoneNumber: "",
        email: "",
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        rol: "",
    });

    function btnSave() {
        if (!alerts.email && !alerts.password && !alerts.confirmation && values.firstName && values.lastName && values.rol && values.email && values.userName && values.password) {
            addUser(values).then(v=>{
                if(v.data.successed){
                    updateValues(states)
                }
            });
        } else {
            setMessage({ status: true, message: "Debe llenar todos los campos obligatorios" })
        }
    };

    const {
        values,
        handleChange,
        updateValues
    } = useFormHelper(states);

    function vemail(e: any) {
        const email: string = e.target.value;
        if (!(email.split("@").length === 2)) {
            updateAlerts({
                ...alerts,
                email: true
            })
        } else {
            updateAlerts({
                ...alerts,
                email: false
            })
        }
        handleChange(e);
    };

    function vpassword(e: any) {
        const password: string = e.target.value;
        if (password.length < 8) {
            updateAlerts({ ...alerts, password: true });
        } else {
            updateAlerts({ ...alerts, password: false });
        }
        handleChange(e);
    };

    function vconfirmation(e: any) {
        const password: string = e.target.value;
        if (password !== values.password) {
            updateAlerts({ ...alerts, confirmation: true });
        } else {
            updateAlerts({ ...alerts, confirmation: false });
        }
        handleChange(e);
    };

    useEffect(() => {
        if (!upd) {
            setUpd(true);
            getUserRols().then(v => {
                if (v.data.successed) {
                    setuserType(v.data.rols);
                };
            });
        };
    }, [upd]);

    return (
        <div className="container-fluid  py-2 pd-d-0">
            <h5><b>Datos personales</b></h5>
            <div className="row">
                <div className="col-md-6 py-2">
                    <TextField value={values.dni} autoComplete="off" onChange={handleChange} name="dni" fullWidth variant="standard" label="DNI" />
                </div>
                <div className="col-md-6 py-2">
                    <TextField value={values.phoneNumber} autoComplete="off" onChange={handleChange} name="phoneNumber" fullWidth variant="standard" label="Telefono/Celular" />
                </div>
                <div className="col-md-6 py-2">
                    <TextField value={values.email} autoComplete="off" error={alerts.email} type="email" onChange={vemail} name="email" fullWidth variant="standard" label="Email*" />
                </div>
                <div className="col-md-6 py-2">
                    <TextField value={values.firstName} autoComplete="off" onChange={handleChange} name="firstName" fullWidth variant="standard" label="Nombres*" />
                </div>
                <div className="col-md-6 py-2">
                    <TextField value={values.lastName} autoComplete="off" onChange={handleChange} name="lastName" fullWidth variant="standard" label="Apellidos*" />
                </div>

            </div>
            <h5 style={{ marginTop: 15 }}><b>Detalles de usuario</b></h5>
            <div className="row">
                <div className="col-md-6 py-2">
                    <TextField value={values.userName} autoComplete="off" onChange={handleChange} name="userName" fullWidth variant="standard" label="Usuario*" />
                </div>
                <div className="col-md-6 py-2">
                    <TextField value={values.password} type="password" error={alerts.password} helperText={alerts.password ? "La contraseña debe contener un minimo de 8 caracteres" : ""} onChange={vpassword} name="password" fullWidth variant="standard" label="Contraseña*" />
                </div>
                <div className="col-md-6 py-2">
                    <TextField fullWidth variant="standard" helperText={alerts.confirmation ? "Las contraseñas deben coincidir" : ""} onChange={vconfirmation} error={alerts.confirmation} type="password" label="Confirme su contraseña*" />
                </div>
                <div className="col-md-6 py-2">
                    <FormControl fullWidth variant="standard">
                        <InputLabel id="l3">Tipo de usuario*</InputLabel>
                        <Select
                            name="rol"
                            defaultValue={values.rol ? values.rol : ""}
                            value={values.rol ? values.rol : ""}
                            onChange={handleChange}
                            labelId="l3"
                            label="Tipo de usuario*"
                        >
                            <MenuItem value="" disabled >Seleccionar un tipo</MenuItem>
                            {userType.map((val: any) =>
                                <MenuItem key={val._id} value={val._id}>{val.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>


            </div>
            <div style={{ marginTop: 30 }} className="alert alert-primary">Los campos marcados con * son obligatorios</div>

            {updating ? <CircularProgress /> : <button className="btn btn-primary" style={{ marginTop: 5 }} onClick={btnSave} >Guardar</button>}
            <div>
                <small style={{ color: "red" }} >{message.status ? message.message : ""}</small>
            </div>
        </div>
    )
};

interface IUser {
    _id: string,
    firstName: string,
    lastName: string,
    userName: string,
    phoneNumber: string,
    email: string
}
const ListaDeUsuarios: React.FC = () => {
    const [users, setUsers] = useState<IUser | undefined>(undefined);
    const [usersTab, setUsersTab] = useState<any>([]);
    const [upd, setUpd] = useState(false);

    useEffect(() => {
        if (!upd) {
            setUpd(true);
            getUsers().then(v => {
                if (v.data.successed) {
                    setUsers(v.data.users);
                    const mtemp: any[] = v.data.users;
                    const mresp: any[] = [];
                    for (let e of mtemp) {
                        const l = { columns: [{ label: `${e.firstName} ${e.lastName}`.toUpperCase() }, { label: e.userName }, { label: e.email }, { label: e.userType[0].name }], link: `/usuarios/${e._id}` };
                        mresp.push(l);
                    };
                    setUsersTab(mresp);
                };
            });
        };
    }, [upd, users]);

    return (
        <div className="py-3">
            <MUITable rows={usersTab} columnLabels={[{ label: "Nombre" }, { label: "Usuario" }, { label: "Correo" }, { label: "Tipo" }]} />
        </div>
    )
};

const TipoDeUsuario: React.FC = () => {
    const [upd, setUpd] = useState(false);
    const [rols, setrols] = useState([]);
    const [name, setname] = useState("");

    function saveCategory(){
        addRol({name:name.toUpperCase()}).then(v=>{
            if(v.data.successed){
                setUpd(false);
                setname("");
            };
        });
    };

    useEffect(() => {
        if (!upd) {
            setUpd(true);
            getUserRols().then(v => {
                if (v.data.successed) {
                    setrols(v.data.rols);
                };
            });
        };
    }, [upd]);

    return (
        <div className="row">
            <div className="col-lg-6">
                <TextField autoComplete="off" name="name" fullWidth variant="standard" label="Tipo de Usuario" onChange={(e) => setname(e.target.value)} value={name}  />
                <Button className="my-3" variant="contained" startIcon={<Save />} onClick={saveCategory} >Guardar</Button>
            </div>
            <div className="col-lg-6">
                {rols.map((val:any) => 
                    <List key={val._id} dense={true}>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete">
                                    <MoreVert />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <SupervisorAccount />
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                                primary={val.name}
                                secondary={false ? 'Secondary text' : null}
                            />
                        </ListItem>
                    </List>
                )}
            </div>
        </div>
    )
};


export default Users;