import { useState, useEffect } from "react";
import { VotacionElement } from "../../components/cards";
import { getVotosVotacionActiva, getSeatCoordenadas } from "../../services/juntanominadora";
import * as React from "react";
import stylesVotaciones from "../../styles/votacionStyles.module.css"

const DiagramaSimple: React.FC = () => {
    const [seatnumbers, setSeatNumbers] = useState<any[]>([]);
    const [coordenadas, setCoordenadas] = useState<any[]>([]);
    const [upd, setupd] = useState(false);

    useEffect(() => {
        if (!upd) {
            setupd(true);
        }

        const inv = setInterval(() => {
            getVotosVotacionActiva().then((v) => {
                if (v.data.successed) {
                    const votes = v.data.content;
                    const seatNumbers = Array.from(new Set(votes.map((item: any) => item.seatnumber)))
                    setSeatNumbers(seatNumbers);
                };
            });

            getSeatCoordenadas().then((v) => {
                if (v.data.successed) {
                    const coordenadas = v.data.content;
                    setCoordenadas(coordenadas);
                };
            });

            
        }, 2000);
        return () => clearInterval(inv);
    }, [upd]);

    return (
        <div className={`container-fluid ${stylesVotaciones.diagramaBackground}`}>
            {coordenadas.map((coor: any, index) => {
                const seat = seatnumbers.filter((seatnumber:any) => { return seatnumber === coor.SeatNumber })
                const voted = seat.length ? true : false;
                return (
                    <div key={coor.SeatNumber}>
                        <VotacionElement top={coor.YCoordinate} left={coor.XCoordinate} id={coor.SeatNumber} voted={voted} />
                    </div>
                )
            })}
        </div>
    )
};

export default DiagramaSimple;
