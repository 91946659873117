import { Button, FormControlLabel, Switch, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import MainContainer from "../../components/container";
import { getApiMeeting, updateApiMeeting } from "../../services/api";
import moment from "moment";
import useFormHelper from "../../helpers/useFormHelper";

const MeetingDetails: React.FC = () => {

    const [upd, setupd] = useState(false);
    const [save, setsave] = useState(false)
    const [meeting, setmeeting] = useState<any>(undefined);
    const { id }: any = useParams();

    const states = useState({
        startDate: "",
        endDate: "",
        link: "",
        link2: "",
        enabled: false,
        state: false
    });

    const {
        values,
        handleChange,
        updateValues
    } = useFormHelper(states);

    useEffect(() => {
        if (!upd) {
            setupd(true);
            getApiMeeting(id).then(v => {
                if (v.data.successed) {
                    const meet:any = v.data.meeting;
                    updateValues({
                        ...values,
                        startDate: meet.startDate,
                        endDate: meet.endDate,
                        link: meet.link,
                        link2: meet.link2,
                        enabled: meet.enabled,
                        state: meet.state
                    });
                    setmeeting(meet);
                };
            });
        };
    }, [upd, meeting, id, values, updateValues]);

    function  onHandleChange(e:any){
        handleChange(e);
        setsave(true);
    };

    function onSave(){
        updateApiMeeting(id, values).then(t => {
            if(t.data.successed){
                window.location.reload()
            };
        });
    };

    function onDateButton(start: boolean){
        if(start){
            onHandleChange({target:{name:"startDate", value: new Date()}});
        }else{
            onHandleChange({target:{name:"endDate", value: new Date()}});
        };
    };

    return (
        <MainContainer name="Reuniones">
            <div className="py-2 container-fluid" style={{ minHeight: "100vh" }}>
                <Link className="my-3" to="/">
                    VOLVER
                </Link>
                <div className="container py-3 rounded shadow-sm mt-3" style={{ minHeight: "65vh" }}>
                    {meeting && (
                        <div className="px-3">
                            <h2 className="mt-2 mb-4"><b>{meeting.topic}</b></h2>
                            <h6 className="my-4">{meeting.lounge}</h6>

                            <h6 className="my-1">Fecha de Inicio: {values.startDate ? moment(values.startDate).format("DD/MM/YY  hh:mm A") : "----"}</h6>
                            <h6 className="my-1 mb-3">Fecha de Finalizacion: {values.endDate ? moment(values.endDate).format("DD/MM/YY  hh:mm A") : "----"}</h6>

                            <TextField value={values.link} className="my-2" fullWidth id="standard-basic" label="Stream Opcion 1" variant="standard" name="link" onChange={onHandleChange} />
                            <TextField value={values.link2} className="my-2" fullWidth id="standard-basic" label="Stream Opcion 1" variant="standard" name="link2" onChange={onHandleChange} />

                            {!(values.startDate && values.endDate) && (
                                <div>
                                    <Button className="my-3" variant="contained" color={!values.startDate?"primary":"error"} onClick={()=>{onDateButton(!values.startDate)}}>{!values.startDate ? "INICIAR" : "TERMINAR"}</Button>
                                </div>
                            )}

                            <div className="container-fluid rounded my-3 py-3 text-center " style={{ backgroundColor: "#E5E5E5" }}>
                                <h6 className="mb-3"><b>Controles</b></h6>
                                <FormControlLabel className="mx-2" control={<Switch onChange={(e) => {onHandleChange({target:{name:e.target.name, value:!values.state}});}} name="state" checked={values.state}  />} label="Presentar" />
                                <FormControlLabel className="mx-2" control={<Switch onChange={(e) => {onHandleChange({target:{name:e.target.name, value:!values.enabled}});}} name="enabled" checked={values.enabled}  />} label="Habilitado" />
                            </div>
                        </div>
                    )}
                    <div className="text-center">
                        <Button className="my-2" onClick={onSave} variant="contained" disabled={!save} >Guardar</Button>
                    </div>
                </div>
            </div>
        </MainContainer>
    )
};

export default MeetingDetails;