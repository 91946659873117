import { Button, ButtonGroup, MenuList, MenuItem, Paper, ClickAwayListener, Grow, Popper } from "@mui/material";
import React from "react";
import { ArrowDropDown, FormatAlignJustify, VideoFile } from "@mui/icons-material";
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import AlignLeft from '@mui/icons-material/FormatAlignLeft';
import AlignRight from '@mui/icons-material/FormatAlignRight';
import AlignCenter from '@mui/icons-material/FormatAlignCenter';
import ImageIcon from '@mui/icons-material/Image';
import UploadImg from "./UploadFile";

import "../styles/Home.module.css";

interface IBarState {
    handleInlineStyle: (command: string) => void;
    handleBlockStyle: (command: string) => void;
    handleImageUpload: (path: string) => void;
    onSavePost: () => void;
    insertVideoLink: (path: string) => void;
};

const blockStyleElements = [
    { label: "H1", param: "header-one" },
    { label: "H2", param: "header-two" },
    { label: "H3", param: "header-three" },
    { label: "H4", param: "header-four" },
    { label: "H5", param: "header-five" },
    { label: "H6", param: "header-six" },
];

const inlineElements = [
    { label: "BOLD", param: "B" },
    { label: "ITALIC", param: "I" },
    { label: "UNDERLINE", param: "U" },
    { label: "STRIKETHROUGH", param: "S" },
    { label: "left", param: <AlignLeft /> },
    { label: "center", param: <AlignCenter /> },
    { label: "right", param: <AlignRight /> },
    { label: "justify", param: <FormatAlignJustify /> },
];


const BarState: React.FC<IBarState> = ({ handleInlineStyle, handleBlockStyle, handleImageUpload, onSavePost, insertVideoLink }) => {

    const [selectedElement, setSelectedIndex] = React.useState(blockStyleElements[0]);

    return (
        <div className="fixed-left">
        <div className="row">
            <div className="col-lg-11">
                <div className="d-flex my-3" >
                    <MenuListMod selectedElement={selectedElement} setSelectedIndex={setSelectedIndex} handleBlockStyle={handleBlockStyle} />

                    <ButtonGroup variant="outlined" className="px-2" >
                        {inlineElements.map((v, index) =>
                            <Button key={index} onClick={() => { handleInlineStyle(v.label) }}>{v.param}</Button>
                        )}
                    </ButtonGroup>

                    <ButtonGroup className="px-2" variant="outlined" >
                        <Button onClick={() => handleBlockStyle("ordered-list-item")} >
                            <FormatListNumbered />
                        </Button>
                        <Button onClick={() => handleBlockStyle("unordered-list-item")} >
                            <FormatListBulleted />
                        </Button>

                    </ButtonGroup>

                    <ModalMod setImgRoute={handleImageUpload} setVideoRoute={insertVideoLink} />



                </div>
            </div>
            <div className="col-lg-1 col-md-12 d-flex  align-items-center">
                <div>
                    <Button style={{ position: "relative" }} variant="contained" onClick={onSavePost}>Guardar</Button>
                </div>
            </div>
        </div>
        </div>
    )
};

interface IMenuListMod {
    setSelectedIndex: (element: any) => void,
    handleBlockStyle: (command: string) => void,
    selectedElement: any
}

const MenuListMod: React.FC<IMenuListMod> = ({ setSelectedIndex, handleBlockStyle, selectedElement }) => {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement | null>(null);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        element: any,
    ) => {
        setSelectedIndex(element);
        handleBlockStyle(element.param);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <ButtonGroup variant="outlined" ref={anchorRef} style={{ marginRight: 15 }} >
                <Button onClick={(e: any) => handleMenuItemClick(e, selectedElement)}>{selectedElement.label}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="top-end"
                transition
                style={{zIndex:200}}
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div className="d-flex my-3 hrstyle shadow" >
                                    {blockStyleElements.map((element, index) =>
                                        <PItem key={index} onClick={(event) => handleMenuItemClick(event, element)} selected={element.label === selectedElement.label} label={element.label} />
                                    )}
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
};

interface IPItem {
    selected?: boolean;
    label: string
    onClick: (e: any) => void;
}

const PItem: React.FC<IPItem> = ({ selected, label, onClick }) => {
    return (
        <div className="" onClick={onClick} >
            <Button variant={selected ? "contained" : "text"} >{label}</Button>
        </div>
    )
}

interface IModalMod {
    setImgRoute: (path: string) => void;
    setVideoRoute: (path: string) => void;
};

const ModalMod: React.FC<IModalMod> = ({ setImgRoute, setVideoRoute }) => {

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const anchorRef = React.useRef<HTMLDivElement>(null);
    const anchorRef2 = React.useRef<HTMLDivElement>(null);

    const [link, setlink] = React.useState("");


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleToggle2 = () => {
        setOpen2((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function InsertVideo(){
        if(link){
            setVideoRoute(link);
            handleToggle2();
        };
    };

    return (
        <div className="d-flex px-2">

            <ButtonGroup variant="outlined" ref={anchorRef} style={{ marginRight: 15 }} >
                <Button
                    size="medium"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ImageIcon />
                </Button>
            </ButtonGroup>

            <ButtonGroup variant="outlined" ref={anchorRef2} style={{ marginRight: 15 }} >
                <Button
                    size="medium"
                    aria-controls={open2 ? 'split-button-menu' : undefined}
                    aria-expanded={open2 ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle2}>
                    <VideoFile />
                </Button>
            </ButtonGroup>

            <Popper
                open={open2}
                anchorEl={anchorRef2.current}
                placement="top-end"
                style={{zIndex:200}}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper className="mx-3">
                            <ClickAwayListener onClickAway={handleToggle2}>
                                <div className="px-4 py-3 ">
                                    <h6><b>Link del video:</b></h6>
                                    <div className="d-flex">
                                        <input className="form-control" onChange={(e) => {setlink(e.target.value)}} style={{ marginRight: 5 }} />
                                        <Button variant="contained" onClick={InsertVideo} >Insertar</Button>
                                    </div>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="top-end"
                role={undefined}
                transition
                style={{zIndex:200}}
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper className="mx-3">
                            <ClickAwayListener onClickAway={handleToggle}>
                                <UploadImg setImgRoute={setImgRoute} handleClose={handleToggle} />
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

export default BarState;