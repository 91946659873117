import React from "react";
import MainContainer from "../../components/container";
import PersistentDrawer, { DrawnerElement } from "../../components/persistenDrawer";
import AddMeeting, { MeetingList } from "../../views/meetings";

const Reuniones: React.FC = () => {
    return (
        <MainContainer name="Reuniones">
            <ReunionesPanel />
        </MainContainer>
    )
};

const ReunionesPanel: React.FC = () => {
    const elements: DrawnerElement[] = [
        { title: "Agregar Reunion", label: "Agregar Reunion", view: <AddMeeting /> },
        { title: "Lista de Reunones", label: "Lista de Reunones", view: <MeetingList /> }
    ];
    const [selected, setselected] = React.useState<DrawnerElement>(elements[0]);
    function onSavedPost(){
        setselected(elements[1]);
    };
    return(
        <PersistentDrawer selected={selected} setselected={setselected} elements={elements}></PersistentDrawer>
    )
}

export default Reuniones;